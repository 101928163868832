// src/components/AccountSettings/ProfileSection.js
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Avatar,
  Stack,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { updateUsername } from '../../services/api';

function ProfileSection({ userProfile, setUserProfile }) {
  useEffect(() => {
    console.log('UserProfile in ProfileSection:', userProfile);
  }, [userProfile]);

  const [openDialog, setOpenDialog] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [password, setPassword] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  if (!userProfile) {
    return <CircularProgress />;
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setNewUsername(userProfile?.username || '');
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedProfile = await updateUsername(userProfile.id, newUsername, password);
      setUserProfile({ ...userProfile, username: updatedProfile.user.username });
      handleCloseDialog();
      setSnackbar({ open: true, message: 'Username updated successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || 'An error occurred',
        severity: 'error',
      });
    }
  };

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ color: 'common.white' }}>
        Profile Information
      </Typography>
      <Stack direction="row" spacing={2} alignItems="center">
        <Avatar sx={{ width: 64, height: 64, bgcolor: 'primary.main' }}>
          {userProfile?.username ? userProfile.username.charAt(0).toUpperCase() : '?'}
        </Avatar>
        <div>
          <Typography variant="h6" sx={{ color: 'common.white' }}>
            {userProfile?.username || 'No username set'}
          </Typography>
          <Button variant="outlined" onClick={handleOpenDialog} sx={{ mt: 1 }}>
            {userProfile?.username ? 'Edit Username' : 'Set Username'}
          </Button>
        </div>
      </Stack>

      {/* Other profile information fields can go here */}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{userProfile.username ? 'Change Username' : 'Set Username'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Username"
            type="text"
            fullWidth
            variant="standard"
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Current Password"
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ProfileSection;