// src/components/AccountSettings/DeleteAccountButton.js
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteAccount } from '../../services/api';

function DeleteAccountButton() {
  const [openDialog, setOpenDialog] = useState(false);
  const [password, setPassword] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleDeleteAccount = async () => {
    try {
      await deleteAccount(password);
      setOpenDialog(false);
      setSnackbar({ open: true, message: 'Account deleted successfully', severity: 'success' });
      // Implement logout and redirect to home page
    } catch (error) {
      setSnackbar({ open: true, message: error.response?.data?.message || 'Error deleting account', severity: 'error' });
    }
  };

  return (
    <>
      <Button
        startIcon={<DeleteIcon />}
        onClick={() => setOpenDialog(true)}
        color="error"
        fullWidth
      >
        Delete Account
      </Button>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your account? This action cannot be undone. Please enter your password to confirm.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteAccount} color="error">
            Delete Account
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DeleteAccountButton;