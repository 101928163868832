// frontend/src/components/AccountSettings/PrivacySection.js
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Switch,
  FormControlLabel,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
} from '@mui/material';
import { styled } from '@mui/system';
import { updatePrivacySettings, requestDataDownload } from '../../services/api';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  color: theme.palette.common.white,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.common.white,
  borderColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const SecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main, // This should be your pink color
  fontSize: '0.875rem',
}));

function PrivacySection() {
  const [privacySettings, setPrivacySettings] = useState({
    profileVisibility: false,
    dataSharing: false,
  });
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const fetchPrivacySettings = async () => {
      try {
        const settings = await updatePrivacySettings(privacySettings);
        setPrivacySettings(settings);
      } catch (error) {
        console.error('Error fetching privacy settings:', error);
        setSnackbar({ open: true, message: 'Failed to load privacy settings', severity: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchPrivacySettings();
  }, []);

  const handleSettingChange = async (setting) => {
    const updatedSettings = { ...privacySettings, [setting]: !privacySettings[setting] };
    setPrivacySettings(updatedSettings);
    try {
      await updatePrivacySettings(updatedSettings);
      setSnackbar({ open: true, message: 'Privacy settings updated', severity: 'success' });
    } catch (error) {
      console.error('Error updating privacy settings:', error);
      setSnackbar({ open: true, message: 'Failed to update privacy settings', severity: 'error' });
    }
  };

  const handleDataDownload = async () => {
    try {
      await requestDataDownload();
      setSnackbar({ open: true, message: 'Data download requested. Check your email.', severity: 'success' });
    } catch (error) {
      console.error('Error requesting data download:', error);
      setSnackbar({ open: true, message: 'Failed to request data download', severity: 'error' });
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <StyledBox>
      <Typography variant="h5" gutterBottom>Privacy Settings</Typography>
      <List>
        <StyledListItem>
          <ListItemText 
            primary="Profile Visibility" 
            secondary={
              <SecondaryText>
                Allow others to view your profile
              </SecondaryText>
            }
          />
          <FormControlLabel
            control={
              <Switch
                checked={privacySettings.profileVisibility}
                onChange={() => handleSettingChange('profileVisibility')}
                color="primary"
              />
            }
            label={privacySettings.profileVisibility ? 'Public' : 'Private'}
          />
        </StyledListItem>
        <StyledListItem>
          <ListItemText 
            primary="Data Sharing" 
            secondary={
              <SecondaryText>
                Share usage data to improve our services
              </SecondaryText>
            }
          />
          <FormControlLabel
            control={
              <Switch
                checked={privacySettings.dataSharing}
                onChange={() => handleSettingChange('dataSharing')}
                color="primary"
              />
            }
            label={privacySettings.dataSharing ? 'Enabled' : 'Disabled'}
          />
        </StyledListItem>
      </List>
      
      <Divider sx={{ my: 2, backgroundColor: 'rgba(255, 255, 255, 0.2)' }} />
      
      <Typography variant="h6" gutterBottom>Data Management</Typography>
      <StyledButton variant="outlined" onClick={handleDataDownload}>
        Download My Data
      </StyledButton>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledBox>
  );
}

export default PrivacySection;