// src/pages/SignIn.js

import React, { useState, useEffect, useCallback } from 'react';
import { Box, TextField, Button, Typography, Link as MuiLink, Alert, Container, Paper } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { loginUser, loginWithGoogle } from '../services/api';
import { handleApiError } from '../utils/frontendErrorHandler';
import { useAuth } from '../contexts/AuthContext';
import GoogleLoginButton from '../components/GoogleLoginButton';
import SpotifyLoginButton from '../components/SpotifyLoginButton';
import { styled, alpha } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiInputBase-input': {
    color: theme.palette.common.white,
    '&:-webkit-autofill': {
      transition: 'background-color 5000s ease-in-out 0s',
      WebkitTextFillColor: theme.palette.common.white,
      caretColor: theme.palette.common.white,
      boxShadow: `0 0 0px 1000px ${alpha(theme.palette.primary.main, 0.1)} inset`,
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.grey[400],
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.main,
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1, 0),
  fontSize: '1.1rem',
  backgroundColor: '#e84c88',
  '&:hover': {
    backgroundColor: '#d43d77',
  },
}));

function SignIn() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
      isValid = false;
    }

    if (!formData.password) {
      newErrors.password = 'Password is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleLogin = async (loginData) => {
    try {
      const response = await loginUser(loginData);
      console.log('Login response:', response);
      
      login({
        token: response.token,
        userId: response.userId,
        username: response.username,
        role: response.role
      });
      
      console.log('Login function called');
      // Navigate to dashboard or home page
      navigate('/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      // Handle login error
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const userData = await loginUser(formData);
      console.log('Full login response in SignIn:', userData);
      
      login({
        token: userData.token,
        userId: userData.userId,
        username: userData.username
      });
      
      console.log('Data passed to login function:', {
        token: userData.token,
        userId: userData.userId,
        username: userData.username
      });

      navigate('/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      const { message } = handleApiError(error);
      setErrorMessage(message);
    }
  };

  const handleGoogleSuccess = async (result) => {
    try {
      console.log('Backend login successful:', result);

      // User is already logged in via the `login` function in `GoogleLoginButton`
      setErrorMessage('');
      navigate('/dashboard');
    } catch (error) {
      console.error('Google login error:', error);
      setErrorMessage(error.response?.data?.message || 'Failed to authenticate with Google. Please try again.');
    }
  };

  const handleGoogleFailure = (error) => {
    console.error('Google login failed:', error);
    
    // Check if the error is due to the user closing the popup
    if (error.error === 'popup_closed_by_user') {
      setErrorMessage('Google Sign-In was cancelled. Please try again if you want to sign in with Google.');
    } else {
      setErrorMessage('Google Sign-In failed. Please try again or use another sign-in method.');
    }
  };

  const handleSpotifyLoginSuccess = useCallback(() => {
    console.log('Spotify login successful, navigating to dashboard');
    navigate('/dashboard');
  }, [navigate]);

  useEffect(() => {
    const handleMessage = (event) => {
      // Since both popup and main window are on the same origin now, this check should pass
      if (event.origin !== window.location.origin) return;

      if (event.data.type === 'SPOTIFY_AUTH_SUCCESS') {
        console.log('Received Spotify auth success message:', event.data);
        login({
          token: event.data.token,
          userId: event.data.userId,
        });
        console.log('Navigating to dashboard after Spotify login');
        navigate('/dashboard');
      } else if (event.data.type === 'SPOTIFY_AUTH_ERROR') {
        console.error('Spotify auth error:', event.data.error);
        setErrorMessage('Spotify authentication failed. Please try again.');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [login, navigate]);

  return (
    <Box sx={{ 
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(to bottom right, #000000, #1a1a1a)',
    }}>
      <Container maxWidth="sm">
        <StyledPaper>
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              textAlign: 'center',
              color: '#e84c88',
              mb: 4,
            }}
          >
            Sign In
          </Typography>
          
          <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
            <StyledTextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              required
              onChange={handleChange}
              value={formData.email}
              error={!!errors.email}
              helperText={errors.email}
            />
            <StyledTextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              required
              onChange={handleChange}
              value={formData.password}
              error={!!errors.password}
              helperText={errors.password}
            />
            <StyledButton 
              variant="contained" 
              type="submit" 
              fullWidth 
            >
              Sign In
            </StyledButton>
            
            <GoogleLoginButton
              onSuccess={handleGoogleSuccess}
              onFailure={handleGoogleFailure}
              sx={{ mb: 2 }}
            />

            <SpotifyLoginButton
              sx={{ mb: 2 }}
              onLoginSuccess={handleSpotifyLoginSuccess}
            />
          </Box>
          
          {errorMessage && (
            <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
              {errorMessage}
            </Alert>
          )}
          <Typography sx={{ mt: 3, textAlign: 'center' }}>
            Don't have an account?{' '}
            <MuiLink component={Link} to="/signup" sx={{ fontWeight: 'bold', color: '#e84c88' }}>
              Sign Up
            </MuiLink>
          </Typography>
          <Typography sx={{ mt: 2, textAlign: 'center' }}>
            <MuiLink component={Link} to="/forgot-password" sx={{ fontWeight: 'bold', color: '#e84c88' }}>
              Forgot Password?
            </MuiLink>
          </Typography>
        </StyledPaper>
      </Container>
    </Box>
  );
}

export default SignIn;