import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function VerifyEmail() {
  const [status, setStatus] = useState('Verifying your email...');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    if (token) {
      verifyEmail(token);
    } else {
      setStatus('Invalid verification link');
    }
  }, [location]);

  const verifyEmail = async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/verify-email?token=${token}`);
      setStatus(response.data.message);
      setTimeout(() => navigate('/signin'), 3000);
    } catch (error) {
      setStatus(error.response?.data?.message || 'Verification failed. Please try again or contact support.');
    }
  };

  return (
    <div>
      <h2>Email Verification</h2>
      <p>{status}</p>
    </div>
  );
}

export default VerifyEmail;