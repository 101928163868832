// src/components/SpotifyCallback.js
import React, { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { exchangeSpotifyCode } from '../services/api';

function SpotifyCallback() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log('SpotifyCallback useEffect called'); // Add this line

    const handleAuth = async () => {
      console.log('handleAuth function called'); // Add this line
      // Use window.location.search
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      const error = params.get('error');

      console.log('Code:', code); // Add this line
      console.log('Error:', error); // Add this line

      if (error) {
        console.error('Spotify authentication error:', error);
        navigate('/signin'); // Redirect to sign-in page on error
      } else if (code) {
        try {
          const data = await exchangeSpotifyCode(code);

          console.log('Received data from exchangeSpotifyCode:', data);

          if (data.token && data.userId) {
            login(data);

            if (window.opener) {
              window.opener.postMessage(
                {
                  type: 'SPOTIFY_AUTH_SUCCESS',
                  token: data.token,
                  userId: data.userId,
                  username: data.username,
                  spotifyId: data.spotifyId,
                },
                window.location.origin
              );
              window.close();
            } else {
              navigate('/dashboard');
            }
          } else {
            console.error('Invalid data received from exchangeSpotifyCode:', data);
            navigate('/signin');
          }
        } catch (err) {
          console.error('Error exchanging code:', err);
          navigate('/signin');
        }
      } else {
        console.error('No code received');
        navigate('/signin');
      }
    };

    handleAuth();
  }, [login, navigate]); // Remove location.search from dependencies if not using it

  return <div>Processing Spotify authentication...</div>;
}

export default SpotifyCallback;