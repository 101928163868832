import React, { useState, useEffect } from 'react';
import { getPageContent } from '../services/api';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import jackHamiltonImage from '../assets/jack-hamilton-CMnNiQBkwBI-unsplash.jpg';

function About() {
  const [aboutContent, setAboutContent] = useState(null);

  useEffect(() => {
    const fetchAboutContent = async () => {
      try {
        const content = await getPageContent('about');
        setAboutContent(content);
      } catch (error) {
        console.error('Error fetching about content:', error);
      }
    };

    fetchAboutContent();
  }, []);

  if (!aboutContent) return <div>Loading...</div>;

  return (
    <Box sx={{ backgroundColor: 'transparent', minHeight: '100vh' }}>
      <Container maxWidth="lg" sx={{ pt: 12, pb: 8 }}>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography variant="h2" gutterBottom sx={{ color: '#FFFFFF', textAlign: 'center', mb: 6 }}>
            Our Story
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 4, bgcolor: 'rgba(255, 255, 255, 0.1)', color: '#FFFFFF', height: '100%' }}>
                <Typography variant="h5" gutterBottom>The Birth of ID BRO</Typography>
                <Typography variant="body1" paragraph>
                  Picture this: It's 3 AM, you're at an underground rave, and the DJ drops an absolute banger. Your heart races, your feet can't stop moving, and you think, "I need this track in my life!" But how do you find it?
                </Typography>
                <Typography variant="body1" paragraph>
                  That's where our journey began. We were just like you – music lovers, constantly Shazaming tracks, frantically typing lyrics into Google, and pestering DJs for track IDs. The struggle was real, and it was exhausting.
                </Typography>
                <Typography variant="body1">
                  One night, after a particularly epic set, we found ourselves huddled outside the club, phones in hand, trying to piece together our musical discoveries. Shazam, Spotify, YouTube, SoundCloud – we were jumping between apps like we were in a digital obstacle course. And then it hit us: there had to be a better way.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={jackHamiltonImage}
                alt="DJ Equipment"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              />
            </Grid>
          </Grid>
          <Paper elevation={3} sx={{ p: 4, bgcolor: 'rgba(255, 255, 255, 0.1)', color: '#FFFFFF', mt: 4 }}>
            <Typography variant="h5" gutterBottom>The ID BRO Revolution</Typography>
            <Typography variant="body1" paragraph>
              That's when ID BRO was born. We envisioned a platform that would seamlessly integrate track identification, playlist creation, and music discovery. No more app-hopping, no more lost tracks, no more "what was that song again?" moments.
            </Typography>
            <Typography variant="body1" paragraph>
              We poured our hearts (and countless sleepless nights) into creating a solution that would revolutionize how music enthusiasts and DJs interact with the tracks they love. With ID BRO, you can identify tracks, create playlists, and connect with like-minded music lovers – all in one place.
            </Typography>
            <Typography variant="body1">
              ID BRO is more than just an app; it's a movement. It's about preserving those magical musical moments, building communities around shared tastes, and never losing track of a track again. Join us in our mission to make every beat count, every ID matter, and every playlist a masterpiece.
            </Typography>
          </Paper>
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Typography variant="h5" sx={{ color: '#FFFFFF' }}>
              Because in the world of music, every ID counts.
            </Typography>
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
}

export default About;
