import React from 'react';
import { Box, Typography, Button, Grid, Container, Switch, FormControlLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { useColorMode } from '../contexts/ColorModeContext';

// Import icon components
import { AiIcon, IntegrationIcon, CommunityIcon } from '../components/Icons';

// Import images
import djImage from '../assets/austrian-national-library-4VgdAcS1lzc-unsplash.jpg';

const NavbarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2, 4),
  backgroundColor: 'transparent',
}));

const NavLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.2rem',
  fontWeight: 500,
  marginRight: theme.spacing(4),
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const LogoTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "'Orbitron', sans-serif",
  fontSize: '3rem',
  fontWeight: 700,
  color: theme.palette.primary.main,
  textDecoration: 'none',
  textShadow: `0 0 10px ${theme.palette.primary.main}`,
  letterSpacing: '3px',
  transition: 'all 0.3s ease',
  '&:hover': {
    textShadow: `0 0 20px ${theme.palette.primary.main}`,
    transform: 'scale(1.05)',
  },
}));

function Home() {
  const theme = useTheme();
  const { toggleColorMode } = useColorMode();

  return (
    <Box sx={{ 
      backgroundColor: theme.palette.background.default,
      minHeight: '100vh',
    }}>
      <NavbarContainer>
        <LogoTypography component={Link} to="/">
          ID BRO
        </LogoTypography>
        <Box>
          <NavLink component={Link} to="/about">About</NavLink>
          <NavLink component={Link} to="/features">Features</NavLink>
          <NavLink component={Link} to="/pricing">Pricing</NavLink>
          <NavLink component={Link} to="/signin">Sign In</NavLink>
          <Button
            variant="contained"
            component={Link}
            to="/signup"
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
              '&:hover': { backgroundColor: theme.palette.secondary.dark },
              ml: 2,
              fontSize: '1.1rem',
              padding: '8px 20px',
            }}
          >
            Sign Up
          </Button>
          <FormControlLabel
            control={<Switch onChange={toggleColorMode} />}
            label={theme.palette.mode === 'dark' ? 'Dark' : 'Light'}
            sx={{ ml: 2 }}
          />
        </Box>
      </NavbarContainer>

      {/* Hero Section */}
      <Container maxWidth="lg" sx={{ pt: 12, pb: 8 }}>
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
            >
              <Typography variant="h1" gutterBottom sx={{ color: theme.palette.text.primary, fontWeight: 700 }}>
                Tired of missing track IDs?
              </Typography>
              <Typography variant="h3" gutterBottom sx={{ mb: 4, color: theme.palette.text.secondary }}>
                ID BRO has got you covered!
              </Typography>
              <Typography variant="h5" sx={{ mb: 4, color: theme.palette.text.secondary }}>
                Welcome to ID BRO, the ultimate platform for DJs and music enthusiasts. Say goodbye to those frustrating "What's that track?" moments.
              </Typography>
              <Button
                variant="contained"
                component={Link}
                to="/signup"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  '&:hover': { backgroundColor: theme.palette.primary.dark },
                  mr: 2,
                  fontSize: '1.2rem',
                  padding: '10px 24px',
                }}
              >
                Get Started
              </Button>
              <Button
                variant="outlined"
                component={Link}
                to="/features"
                sx={{
                  color: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                  fontSize: '1.2rem',
                  padding: '10px 24px',
                }}
              >
                Learn More
              </Button>
            </motion.div>
          </Grid>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              style={{ height: '100%', display: 'flex', alignItems: 'center' }}
            >
              <Box
                component="img"
                src={djImage}
                alt="DJ at work"
                sx={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'cover',
                  borderRadius: 4,
                  boxShadow: 6,
                }}
              />
            </motion.div>
          </Grid>
        </Grid>
      </Container>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ mt: 12, mb: 12 }}>
        <Typography variant="h2" align="center" gutterBottom sx={{ color: theme.palette.text.primary, mb: 8 }}>
          Why Choose ID BRO?
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <AiIcon width={80} height={80} color={theme.palette.primary.main} />
              <Typography variant="h4" sx={{ mb: 2, mt: 2, color: theme.palette.text.primary }}>
                AI-Powered Playlists
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.text.secondary }}>
                Let our intelligent algorithms curate the perfect playlist for any mood or occasion.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <IntegrationIcon width={80} height={80} color={theme.palette.primary.main} />
              <Typography variant="h4" sx={{ mb: 2, mt: 2, color: theme.palette.text.primary }}>
                Seamless Integration
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.text.secondary }}>
                Easily connect with popular platforms like SoundCloud and YouTube.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <CommunityIcon width={80} height={80} color={theme.palette.primary.main} />
              <Typography variant="h4" sx={{ mb: 2, mt: 2, color: theme.palette.text.primary }}>
                Vibrant Community
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.text.secondary }}>
                Join a network of DJs and music lovers to share and discover new music.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Call to Action */}
      <Box sx={{ textAlign: 'center', mb: 12 }}>
        <Typography variant="h2" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Ready to be a Serious Crate Digger?
        </Typography>
        <Button
          variant="contained"
          component={Link}
          to="/signup"
          sx={{
            mt: 4,
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': { backgroundColor: theme.palette.secondary.dark },
            fontSize: '1.4rem',
            padding: '12px 32px',
          }}
        >
          Join Now
        </Button>
      </Box>
    </Box>
  );
}

export default Home;