// Frontend error handler

export const handleApiError = (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return {
        message: error.response.data.message || 'An error occurred. Please try again.',
        status: error.response.status
      };
    } else if (error.request) {
      // The request was made but no response was received
      return {
        message: 'No response from server. Please check your internet connection.',
        status: 'NETWORK_ERROR'
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      return {
        message: 'An unexpected error occurred. Please try again later.',
        status: 'UNKNOWN_ERROR'
      };
    }
  };
  
  export const displayErrorMessage = (error) => {
    const { message } = handleApiError(error);
    // You could integrate this with your notification system
    console.error(message);
    return message;
  };
  
  // You can add more specific error handlers here
  export const handleAuthError = (error) => {
    const { status, message } = handleApiError(error);
    if (status === 401) {
      // Handle unauthorized access
      return 'Invalid credentials. Please try again.';
    }
    return message;
  };