// src/components/AnalysisInProgressModal.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, CircularProgress, Box } from '@mui/material';

const AnalysisInProgressModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Analysis in Progress</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" mb={2}>
          <CircularProgress size={24} sx={{ mr: 2 }} />
          <Typography>Your mix is being analyzed...</Typography>
        </Box>
        <Typography variant="body2">
          This process may take a few minutes. You can safely leave this page and we'll notify you when it's complete.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AnalysisInProgressModal;