import { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { checkTokenExpiration, getTokenExpirationTime } from '../utils/tokenManager';

export const useSession = (onSessionExpired) => {
  const { logout } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      if (!checkTokenExpiration(token)) {
        logout();
        onSessionExpired();
        return;
      }

      const expirationTime = getTokenExpirationTime(token);
      if (expirationTime) {
        const timeoutId = setTimeout(() => {
          logout();
          onSessionExpired();
        }, expirationTime - Date.now());

        return () => clearTimeout(timeoutId);
      }
    }
  }, [logout, onSessionExpired]);
};