import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Button } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { loginWithGoogle } from '../services/api';

const GoogleLoginButton = ({ sx, onSuccess, onFailure }) => {
  const { login } = useAuth();

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log('Google tokenResponse:', tokenResponse);
      try {
        const result = await loginWithGoogle(tokenResponse);
        console.log('Backend login result:', result);

        login({
          token: result.token,
          userId: result.userId,
          username: result.username,
        });

        // Call the onSuccess prop after successful login
        if (onSuccess) onSuccess(result);

      } catch (error) {
        console.error('Error during Google login:', error);
        // Call the onFailure prop if provided
        if (onFailure) onFailure(error);
      }
    },
    onError: (error) => {
      console.error('Google Login Error:', error);
      // Call the onFailure prop if provided
      if (onFailure) onFailure(error);
    },
    scope: 'openid email profile', // Ensure these scopes are included
  });

  return (
    <Button
      onClick={() => handleGoogleLogin()}
      startIcon={<GoogleIcon />}
      variant="contained"
      fullWidth
      sx={{
        backgroundColor: '#4285F4',
        color: 'white',
        '&:hover': {
          backgroundColor: '#357ae8',
        },
        ...sx,
      }}
    >
      Sign in with Google
    </Button>
  );
};

export default GoogleLoginButton;