// frontend/src/components/AccountSettings/BillingSection.js
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  CardActions,
  Grid,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Paper,
} from '@mui/material';
import { styled } from '@mui/system';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, LinkAuthenticationElement, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { getSubscriptionInfo, createSetupIntent, createSubscription, cancelSubscription } from '../../services/api';

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  borderColor: theme.palette.common.white,
  '&:hover': {
    borderColor: theme.palette.common.white,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  color: theme.palette.common.white,
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(5px)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    background: 'rgba(255, 255, 255, 0.8)',
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(3),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(4),
  color: 'black', // Ensure text is black
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  background: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 8,
  color: 'black', // Ensure text is black
}));

const StyledTypography = styled(Typography)({
  color: 'black', // Ensure all Typography components have black text
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PlanCard = ({ plan, currentPlan, onSubscribe }) => (
  <StyledCard>
    <CardContent>
      <StyledTypography variant="h5" component="div">
        {plan.name}
      </StyledTypography>
      <StyledTypography variant="h6">${plan.price}/month</StyledTypography>
      <StyledTypography variant="body2">{plan.description}</StyledTypography>
    </CardContent>
    <CardActions>
      <StyledButton 
        onClick={() => onSubscribe(plan.id)} 
        disabled={currentPlan === plan.id}
      >
        {currentPlan === plan.id ? 'Current Plan' : 'Subscribe'}
      </StyledButton>
    </CardActions>
  </StyledCard>
);

const CheckoutForm = ({ planId, planName, planPrice, onSuccess, onCancel }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const steps = ['Review Plan', 'Payment Details', 'Confirmation'];

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setError(submitError.message);
      setProcessing(false);
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      elements,
      params: {
        type: 'card',
      },
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
    } else {
      try {
        const result = await createSubscription(planId, paymentMethod.id);
        if (result.status === 'active' || result.status === 'trialing') {
          onSuccess();
        } else {
          setError('Subscription creation failed');
        }
      } catch (subscriptionError) {
        setError(subscriptionError.message);
      }
      setProcessing(false);
    }

    setActiveStep(2); // Move to confirmation step
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
              <StyledTypography>{label}</StyledTypography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && (
        <StyledPaper elevation={0}>
          <StyledTypography variant="h6" gutterBottom>
            Plan Details
          </StyledTypography>
          <StyledTypography variant="body1">
            You've selected the <strong>{planName}</strong> plan.
          </StyledTypography>
          <StyledTypography variant="body1">
            Price: <strong>${planPrice}/month</strong>
          </StyledTypography>
        </StyledPaper>
      )}

      {activeStep === 1 && (
        <StyledPaper elevation={0}>
          <StyledTypography variant="h6" gutterBottom>
            Payment Information
          </StyledTypography>
          <PaymentElement options={{
            layout: {
              type: 'tabs',
              defaultCollapsed: false,
            },
          }} />
        </StyledPaper>
      )}

      {activeStep === 2 && (
        <StyledPaper elevation={0}>
          <StyledTypography variant="h6" gutterBottom>
            Confirmation
          </StyledTypography>
          <StyledTypography variant="body1">
            Your subscription has been successfully processed. Thank you for subscribing!
          </StyledTypography>
        </StyledPaper>
      )}

      {error && (
        <StyledTypography color="error" variant="body2" sx={{ mt: 2 }}>
          {error}
        </StyledTypography>
      )}

      <DialogActions>
        {activeStep < 2 && (
          <StyledButton onClick={onCancel} color="secondary">
            Cancel
          </StyledButton>
        )}
        {activeStep === 0 && (
          <StyledButton onClick={() => setActiveStep(1)} variant="contained" color="primary">
            Next
          </StyledButton>
        )}
        {activeStep === 1 && (
          <StyledButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={!stripe || processing}
          >
            {processing ? <CircularProgress size={24} /> : 'Subscribe'}
          </StyledButton>
        )}
        {activeStep === 2 && (
          <StyledButton onClick={onSuccess} variant="contained" color="primary">
            Close
          </StyledButton>
        )}
      </DialogActions>
    </form>
  );
};

function SubscriptionDialog({ open, onClose, planId, planName, planPrice, clientSecret }) {
  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledDialogTitle>Subscribe to {planName}</StyledDialogTitle>
      <StyledDialogContent>
        {clientSecret ? (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret,
              appearance: {
                theme: 'stripe',
                variables: {
                  colorPrimary: '#1a73e8',
                  colorBackground: '#ffffff',
                  colorText: '#30313d',
                  colorDanger: '#df1b41',
                  fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                  borderRadius: '4px',
                },
              },
            }}
          >
            <CheckoutForm
              planId={planId}
              planName={planName}
              planPrice={planPrice}
              onSuccess={onClose}
              onCancel={onClose}
            />
          </Elements>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height={200}>
            <CircularProgress />
          </Box>
        )}
      </StyledDialogContent>
    </StyledDialog>
  );
}

function BillingSection() {
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    const fetchSubscriptionInfo = async () => {
      try {
        const info = await getSubscriptionInfo();
        setSubscriptionInfo(info);
      } catch (error) {
        console.error('Error fetching subscription info:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionInfo();
  }, []);

  const handleSubscribe = async (planId) => {
    console.log('Subscribe clicked for plan:', planId);
    setSelectedPlan(planId);
    try {
      console.log('Creating setup intent...');
      const { clientSecret: secret } = await createSetupIntent();
      console.log('Setup intent created, client secret:', secret);
      setClientSecret(secret);
      setOpenDialog(true);
    } catch (error) {
      console.error('Error creating setup intent:', error);
    }
  };

  const handleSubscriptionSuccess = async () => {
    setOpenDialog(false);
    setLoading(true);
    const info = await getSubscriptionInfo();
    setSubscriptionInfo(info);
    setLoading(false);
  };

  const handleCancelSubscription = async () => {
    try {
      await cancelSubscription();
      const info = await getSubscriptionInfo();
      setSubscriptionInfo(info);
    } catch (error) {
      console.error('Error cancelling subscription:', error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  const plans = [
    { id: 'price_1234', name: 'Basic', price: 9.99, description: 'Basic features' },
    { id: 'price_5678', name: 'Pro', price: 19.99, description: 'Advanced features' },
    { id: 'price_9012', name: 'Enterprise', price: 49.99, description: 'All features + priority support' },
  ];

  return (
    <Box sx={{ color: 'common.white' }}>
      <Typography variant="h5" gutterBottom>
        Billing & Subscription
      </Typography>
      
      {subscriptionInfo && (
        <Box mb={4}>
          <Typography variant="h6">Current Plan</Typography>
          <Typography>
            {plans.find(p => p.id === subscriptionInfo.planId)?.name || 'Free'}
            <Chip 
              label={subscriptionInfo.status} 
              color={subscriptionInfo.status === 'active' ? 'success' : 'error'}
              size="small"
              sx={{ ml: 1 }}
            />
          </Typography>
          {subscriptionInfo.status === 'active' && (
            <StyledButton onClick={handleCancelSubscription} sx={{ mt: 1 }}>
              Cancel Subscription
            </StyledButton>
          )}
        </Box>
      )}

      <Typography variant="h6" gutterBottom>Available Plans</Typography>
      <Grid container spacing={3}>
        {plans.map((plan) => (
          <Grid item xs={12} sm={6} md={4} key={plan.id}>
            <PlanCard 
              plan={plan} 
              currentPlan={subscriptionInfo?.planId} 
              onSubscribe={handleSubscribe} 
            />
          </Grid>
        ))}
      </Grid>

      <SubscriptionDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        planId={selectedPlan}
        planName={plans.find(p => p.id === selectedPlan)?.name}
        planPrice={plans.find(p => p.id === selectedPlan)?.price}
        clientSecret={clientSecret}
      />
    </Box>
  );
}

export default BillingSection;