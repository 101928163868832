import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Link as MuiLink, Alert, Container, Paper } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { registerUser, resendVerificationEmail } from '../services/api';
import { handleApiError } from '../utils/frontendErrorHandler';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiInputBase-input': {
    color: theme.palette.text.primary,
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.divider,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1, 0),
  fontSize: '1.1rem',
  backgroundColor: '#e84c88',
  '&:hover': {
    backgroundColor: '#d43d77',
  },
}));

function SignUp() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [showResendVerification, setShowResendVerification] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {};

    // Username validation
    if (!formData.username.trim()) {
      newErrors.username = 'Username is required';
      isValid = false;
    } else if (!/^[a-zA-Z0-9_]+$/.test(formData.username)) {
      newErrors.username = 'Username can only contain letters, numbers, and underscores';
      isValid = false;
    }

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      isValid = false;
    }

    // Password validation
    if (!formData.password) {
      newErrors.password = 'Password is required';
      isValid = false;
    } else if (formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters long';
      isValid = false;
    } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(formData.password)) {
      newErrors.password = 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character';
      isValid = false;
    }

    // Confirm Password validation
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await registerUser(formData);
      setSuccessMessage('Registration successful! Please check your email for verification instructions.');
      setErrorMessage('');
      setFormData({ username: '', email: '', password: '', confirmPassword: '' });
      setTimeout(() => navigate('/signin'), 3000);
    } catch (error) {
      const { message, status } = handleApiError(error);
      console.error('Error registering user:', message);
      if (status === 409) { // Assuming 409 is used for email already registered
        if (error.response.data.showForgotPassword) {
          setErrorMessage('This email is already registered. Did you forget your password?');
        } else if (error.response.data.showResendVerification) {
          setErrorMessage('This email is registered but not verified. Would you like to resend the verification email?');
          setShowResendVerification(true);
        }
      } else {
        setErrorMessage(message);
      }
      setSuccessMessage('');
    }
  };

  const handleResendVerification = async () => {
    try {
      await resendVerificationEmail(formData.email);
      setSuccessMessage('Verification email resent successfully. Please check your inbox.');
      setErrorMessage('');
      setShowResendVerification(false);
    } catch (error) {
      const { message } = handleApiError(error);
      setErrorMessage(message);
    }
  };

  return (
    <Box sx={{ 
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(to bottom right, #000000, #1a1a1a)',
    }}>
      <Container maxWidth="sm">
        <StyledPaper>
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              textAlign: 'center',
              color: '#e84c88',
              mb: 4,
            }}
          >
            Sign Up
          </Typography>
          
          <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
            <StyledTextField
              fullWidth
              label="Username"
              name="username"
              required
              onChange={handleChange}
              value={formData.username}
              error={!!errors.username}
              helperText={errors.username}
            />
            <StyledTextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              required
              onChange={handleChange}
              value={formData.email}
              error={!!errors.email}
              helperText={errors.email}
            />
            <StyledTextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              required
              onChange={handleChange}
              value={formData.password}
              error={!!errors.password}
              helperText={errors.password}
            />
            <StyledTextField
              fullWidth
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              required
              onChange={handleChange}
              value={formData.confirmPassword}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
            />
            <StyledButton 
              variant="contained" 
              type="submit" 
              fullWidth 
            >
              Register
            </StyledButton>
          </Box>
          
          {successMessage && (
            <Alert severity="success" sx={{ mt: 2, width: '100%' }}>
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
              {errorMessage}
            </Alert>
          )}
          {showResendVerification && (
            <StyledButton 
              onClick={handleResendVerification} 
              fullWidth 
              variant="outlined"
              sx={{
                color: '#e84c88',
                borderColor: '#e84c88',
                '&:hover': {
                  borderColor: '#d43d77',
                  backgroundColor: 'rgba(232, 76, 136, 0.1)',
                },
              }}
            >
              Resend Verification Email
            </StyledButton>
          )}
          <Typography sx={{ mt: 3, textAlign: 'center' }}>
            Already have an account?{' '}
            <MuiLink component={Link} to="/signin" sx={{ fontWeight: 'bold', color: '#e84c88' }}>
              Sign In
            </MuiLink>
          </Typography>
        </StyledPaper>
      </Container>
    </Box>
  );
}

export default SignUp;