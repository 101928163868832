// src/components/AccountSettings/AccountSection.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import DeleteAccountButton from './DeleteAccountButton';

function AccountSection() {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Account Management
      </Typography>
      <DeleteAccountButton />
    </Box>
  );
}

export default AccountSection;