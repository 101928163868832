// frontend/src/components/SpotifyLoginButton.js
import React from 'react';
import { Button } from '@mui/material';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { initiateSpotifyAuth } from '../services/api';

function SpotifyLoginButton({ sx }) {
  const handleSpotifyLogin = async () => {
    try {
      const { authUrl } = await initiateSpotifyAuth();
      const width = 450;
      const height = 730;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;
      
      window.open(
        authUrl,
        'Spotify Login',
        `width=${width},height=${height},left=${left},top=${top}`
      );
    } catch (error) {
      console.error('Error initiating Spotify auth:', error);
    }
  };

  return (
    <Button
      variant="contained"
      onClick={handleSpotifyLogin}
      startIcon={<MusicNoteIcon />}
      fullWidth
      sx={{
        backgroundColor: '#1DB954',
        color: '#FFFFFF',
        '&:hover': { backgroundColor: '#1ED760' },
        fontSize: '1.1rem',
        padding: '10px 0',
        ...sx,
      }}
    >
      Continue with Spotify
    </Button>
  );
}

export default SpotifyLoginButton;
