import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Alert,
  Skeleton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  Divider,
  IconButton,
  Button,
  Stack,
  Paper,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getUserTracklists } from '../services/api';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import { keyframes } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(28, 28, 28, 0.8)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: '#fff',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 8px 40px rgba(0, 0, 0, 0.2)',
    transform: 'translateY(-5px)',
  },
}));

const GradientTypography = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF6B6B, #4ECDC4)',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: '#fff',
  borderRadius: '12px',
  padding: '10px 20px',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    transform: 'translateY(-2px)',
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: '#4ECDC4',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    color: '#FF6B6B',
    transform: 'scale(1.1)',
  },
}));

const moveShape = keyframes`
  0%, 100% { transform: translate(0, 0) scale(1); }
  50% { transform: translate(-30px, -30px) scale(1.2); }
`;

const AnimatedBackground = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
  '& svg': {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

const Shape = styled('circle')(({ theme }) => ({
  fill: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.05)',
  animation: `${moveShape} 15s infinite ease-in-out`,
}));

function MyTracklists() {
  const { user } = useAuth();
  const [tracklists, setTracklists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTracklists = async () => {
      try {
        setLoading(true);
        const userTracklists = await getUserTracklists();
        setTracklists(userTracklists);
      } catch (error) {
        console.error('Error fetching tracklists:', error);
        setError('Failed to load tracklists. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchTracklists();
    } else {
      setLoading(false);
    }
  }, [user]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Skeleton variant="rectangular" width="80%" height={400} animation="wave" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6" color="error">
          Please log in to view your tracklists.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      display: 'flex',
      minHeight: '100vh',
    }}>
      <AnimatedBackground>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" preserveAspectRatio="none">
          <Shape cx="500" cy="500" r="250" />
        </svg>
      </AnimatedBackground>
      <Container maxWidth="lg" sx={{ 
        position: 'relative', 
        zIndex: 2, 
        py: 4,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}>
        <Stack spacing={4} sx={{ flexGrow: 1 }}>
          <StyledPaper sx={{ flexGrow: 1, overflowY: 'auto' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#4ECDC4' }}>Your Tracklists</Typography>
              <ActionButton startIcon={<AddIcon />} component={Link} to="/analyze-mix">
                Analyze New Mix
              </ActionButton>
            </Stack>
            {tracklists.length === 0 ? (
              <Typography>You haven't created any tracklists yet.</Typography>
            ) : (
              <List>
                {tracklists.map((tracklist) => (
                  <React.Fragment key={tracklist._id}>
                    <ListItem
                      sx={{
                        backgroundColor: 'rgba(33, 32, 35, 0.5)',
                        borderRadius: 2,
                        mb: 2,
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: '#FF6B6B' }}>
                          <PlaylistPlayIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="h6" sx={{ color: '#fff' }}>
                            {tracklist.name}
                          </Typography>
                        }
                        secondary={
                          <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                            Created: {new Date(tracklist.createdAt).toLocaleDateString()} | Tracks: {tracklist.tracks.length}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <StyledIconButton
                          component={Link}
                          to={`/tracklist/${tracklist._id}`}
                          aria-label="view"
                        >
                          <VisibilityIcon />
                        </StyledIconButton>
                        <StyledIconButton
                          component={Link}
                          to={`/tracklist/${tracklist._id}/edit`}
                          aria-label="edit"
                        >
                          <EditIcon />
                        </StyledIconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
                  </React.Fragment>
                ))}
              </List>
            )}
          </StyledPaper>
        </Stack>
      </Container>
    </Box>
  );
}

export default MyTracklists;