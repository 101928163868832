import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Switch,
  FormControlLabel,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import { getNotificationSettings, updateNotificationSettings } from '../../services/api';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  color: theme.palette.common.white,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const SecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main, // This should be your pink color
  fontSize: '0.875rem',
}));

function NotificationsSection() {
  const [notificationSettings, setNotificationSettings] = useState({
    emailNotifications: false,
    pushNotifications: false,
    newMixAlerts: false,
    commentNotifications: false,
    marketingEmails: false,
  });
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const fetchNotificationSettings = async () => {
      try {
        const settings = await getNotificationSettings();
        setNotificationSettings(settings);
      } catch (error) {
        console.error('Error fetching notification settings:', error);
        setSnackbar({ open: true, message: 'Failed to load notification settings', severity: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchNotificationSettings();
  }, []);

  const handleSettingChange = async (setting) => {
    const updatedSettings = { ...notificationSettings, [setting]: !notificationSettings[setting] };
    setNotificationSettings(updatedSettings);
    try {
      await updateNotificationSettings(updatedSettings);
      setSnackbar({ open: true, message: 'Notification settings updated', severity: 'success' });
    } catch (error) {
      console.error('Error updating notification settings:', error);
      setSnackbar({ open: true, message: 'Failed to update notification settings', severity: 'error' });
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <StyledBox>
      <Typography variant="h5" gutterBottom>Notification Preferences</Typography>
      <List>
        <StyledListItem>
          <ListItemText 
            primary="Email Notifications" 
            secondary={
              <SecondaryText>
                Receive important updates via email
              </SecondaryText>
            }
          />
          <FormControlLabel
            control={
              <Switch
                checked={notificationSettings.emailNotifications}
                onChange={() => handleSettingChange('emailNotifications')}
                color="primary"
              />
            }
            label={notificationSettings.emailNotifications ? 'Enabled' : 'Disabled'}
          />
        </StyledListItem>
        <StyledListItem>
          <ListItemText 
            primary="Push Notifications" 
            secondary={
              <SecondaryText>
                Receive notifications on your device
              </SecondaryText>
            }
          />
          <FormControlLabel
            control={
              <Switch
                checked={notificationSettings.pushNotifications}
                onChange={() => handleSettingChange('pushNotifications')}
                color="primary"
              />
            }
            label={notificationSettings.pushNotifications ? 'Enabled' : 'Disabled'}
          />
        </StyledListItem>
        <StyledListItem>
          <ListItemText 
            primary="New Mix Alerts" 
            secondary={
              <SecondaryText>
                Get notified when new mixes are available
              </SecondaryText>
            }
          />
          <FormControlLabel
            control={
              <Switch
                checked={notificationSettings.newMixAlerts}
                onChange={() => handleSettingChange('newMixAlerts')}
                color="primary"
              />
            }
            label={notificationSettings.newMixAlerts ? 'Enabled' : 'Disabled'}
          />
        </StyledListItem>
        <StyledListItem>
          <ListItemText 
            primary="Marketing Emails" 
            secondary={
              <SecondaryText>
                Receive promotional offers and updates
              </SecondaryText>
            }
          />
          <FormControlLabel
            control={
              <Switch
                checked={notificationSettings.marketingEmails}
                onChange={() => handleSettingChange('marketingEmails')}
                color="primary"
              />
            }
            label={notificationSettings.marketingEmails ? 'Subscribed' : 'Unsubscribed'}
          />
        </StyledListItem>
      </List>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledBox>
  );
}

export default NotificationsSection;
