// src/App.js

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CssBaseline } from '@mui/material';
import { ColorModeProvider } from './contexts/ColorModeContext';
import AuthProvider from './contexts/AuthContext';
import ErrorBoundary from './components/ErrorBoundary.js';
import AppContent from './components/AppContent';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID; // Make sure this is set in your .env file

function App() {
  return (
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <ColorModeProvider>
          <CssBaseline />
          <AuthProvider>
            <Router>
              <AppContent />
            </Router>
          </AuthProvider>
        </ColorModeProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
}

export default App;
