// src/services/api.js

import axios from 'axios';
import { checkTokenExpiration, getTokenExpirationTime } from '../utils/tokenManager';
import { jwtDecode } from 'jwt-decode';

// Set the base URL for the API
const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 
    (process.env.NODE_ENV === 'production' 
      ? 'https://idbro.onrender.com/api' 
      : 'http://localhost:5001/api'),
  withCredentials: true
});

console.log('API base URL:', API.defaults.baseURL);

// Interceptor for logging and token management
API.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  if (userId) {
    config.headers['User-ID'] = userId;
  }
  console.log('Request headers:', config.headers);
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Helper function for token refresh
const refreshToken = async () => {
  try {
    const response = await API.post('/auth/refresh-token');
    const { token } = response.data;
    localStorage.setItem('token', token);
    return token;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    localStorage.clear();
    window.location.href = '/signin';
    throw error;
  }
};

// Authentication functions
export const registerUser = (userData) => API.post('/auth/register', userData);
export const loginUser = async (credentials) => {
  try {
    const response = await API.post('/auth/login', credentials);
    console.log('API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};
export const signIn = loginUser;
export const verifyEmail = (token) => API.get(`/auth/verify-email?token=${token}`);
export const resendVerificationEmail = (email) => 
  API.post('/auth/resend-verification', { email })
    .catch(error => {
      console.error('API error:', error.response ? {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers
      } : error.message);
      throw error;
    });

// User-related functions
export const getUserActivity = async (userId) => {
  try {
    const response = await API.get(`/users/${userId}/activity`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user activity:', error);
    throw error;
  }
};

export const getUserMixes = async (userId) => {
  try {
    const response = await API.get(`/users/${userId}/mixes`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user mixes:', error);
    throw error;
  }
};

export const getUserProfile = async (userId) => {
  try {
    const response = await API.get(`/users/${userId}/profile`);
    console.log('API response for user profile:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

export const updateUserProfile = async (userId, profileData) => {
  try {
    const response = await API.put(`/users/${userId}/profile`, profileData);
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

// Tracklist-related functions
export const analyzeMix = async (input, tracklistName, updateProgress = () => {}) => {
  try {
    const formData = new FormData();
    if (input instanceof File) {
      formData.append('file', input);
    } else {
      formData.append('url', input);
    }
    formData.append('name', tracklistName);

    const response = await API.post('/audio/analyze', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        updateProgress('Uploading file', percentCompleted);
      },
    });

    // After getting the initial analysis, search for Spotify data
    const tracksWithSpotifyData = await Promise.all((response.data.tracks || []).map(async (track, index) => {
      updateProgress('Fetching Spotify data', Math.round((index / response.data.tracks.length) * 100));
      try {
        const spotifyData = await searchSpotifyTrack(`${track.title} ${track.artist}`);
        if (spotifyData.tracks && spotifyData.tracks.items.length > 0) {
          const spotifyTrack = spotifyData.tracks.items[0];
          return {
            ...track,
            spotifyId: spotifyTrack.id,
            previewUrl: spotifyTrack.preview_url,
            isLiked: false // You might want to check this against the user's liked tracks
          };
        }
      } catch (error) {
        console.error('Error fetching Spotify data for track:', track.title, error);
      }
      return track;
    }));

    updateProgress('Analysis complete', 100);

    return {
      ...response.data,
      tracks: tracksWithSpotifyData,
      analysisId: response.data.analysisId || 'temp-id', // Ensure there's always an analysisId
    };
  } catch (error) {
    console.error('Error analyzing mix:', error);
    throw error;
  }
};

export const getTracklistById = async (id) => {
  try {
    console.log('Fetching tracklist with ID:', id);
    const response = await API.get(`/tracklists/${id}`);
    console.log('API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching tracklist by ID:', error.response?.data || error.message);
    throw error;
  }
};

export const saveTracklist = async (tracklistData) => {
  try {
    console.log('Sending tracklist data:', tracklistData);
    const response = await API.post('/tracklists', tracklistData);
    console.log('Received response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error saving tracklist:', error.response?.data || error.message);
    throw error;
  }
};

export const getUserTracklists = async () => {
  try {
    const response = await API.get('/tracklists');
    return response.data;
  } catch (error) {
    console.error('Error in getUserTracklists:', error);
    throw error;
  }
};

export const updateTracklist = async (id, updatedTracklist) => {
  try {
    const response = await API.put(`/tracklists/${id}`, updatedTracklist);
    return response.data;
  } catch (error) {
    console.error('Error updating tracklist:', error);
    throw error;
  }
};

// Spotify-related functions
export const initiateSpotifyAuth = async () => {
  try {
    const response = await API.get('/spotify/auth');
    return response.data.authUrl;
  } catch (error) {
    console.error('Error initiating Spotify auth:', error);
    throw error;
  }
};

export const exchangeSpotifyCode = async (code) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/spotify/exchange-code`,
      { code },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error exchanging Spotify code:', error.response?.data || error.message);
    throw error;
  }
};

export const searchSpotifyTrack = async (query) => {
  try {
    const response = await API.get(`/spotify/search?q=${encodeURIComponent(query)}`);
    return response.data;
  } catch (error) {
    console.error('Error searching Spotify track:', error);
    throw error;
  }
};

export const createSpotifyPlaylist = async (playlistName, trackIds) => {
  try {
    const response = await API.post('/spotify/create-playlist', { playlistName, trackIds });
    return response.data;
  } catch (error) {
    console.error('Error creating Spotify playlist:', error.response?.data || error.message);
    if (error.response?.status === 401) {
      throw new Error('Spotify authentication required');
    }
    throw error;
  }
};

export const getTrackPreview = async (spotifyId) => {
  const response = await API.get(`/spotify/preview/${spotifyId}`);
  return response.data.previewUrl;
};

export const saveTrackToLibrary = async (spotifyId) => {
  try {
    const response = await API.post(`/spotify/save-track/${spotifyId}`);
    return response.data;
  } catch (error) {
    console.error('Error saving track:', error.response?.data || error.message);
    throw error;
  }
};

export const removeTrackFromLibrary = async (spotifyId) => {
  try {
    const response = await API.delete(`/spotify/remove-track/${spotifyId}`);
    return response.data;
  } catch (error) {
    await handleSpotifyError(error);
  }
};

const handleSpotifyReAuth = async () => {
  try {
    await API.post('/auth/clear-spotify-tokens');
    const authUrl = await initiateSpotifyAuth();
    window.location.href = authUrl;
  } catch (error) {
    console.error('Error initiating Spotify re-authentication:', error);
    throw new Error('Failed to re-authenticate with Spotify');
  }
};

export const handleSpotifyError = async (error) => {
  if (error.message.includes('Insufficient client scope') || error.message === 'SPOTIFY_AUTH_REQUIRED') {
    await handleSpotifyReAuth();
  } else {
    console.error('Spotify API error:', error);
    throw error;
  }
};

export const isSpotifyConnected = () => {
  const spotifyToken = localStorage.getItem('spotifyToken');
  const tokenExpiration = localStorage.getItem('spotifyTokenExpiration');
  const now = new Date().getTime();
  
  return spotifyToken && tokenExpiration && now < parseInt(tokenExpiration);
};

// Add any other functions that were in your original 500-line file here

export const createSubscription = async (planId) => {
  try {
    const response = await API.post('/subscriptions', { planId });
    return response.data;
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};

export const getSubscriptionInfo = async () => {
  try {
    const response = await API.get('/subscriptions');
    return response.data;
  } catch (error) {
    console.error('Error getting subscription info:', error);
    throw error;
  }
};

export const createSetupIntent = async () => {
  try {
    const response = await API.post('/payments/setup-intent');
    return response.data;
  } catch (error) {
    console.error('Error creating setup intent:', error);
    throw error;
  }
};

export const cancelSubscription = async () => {
  try {
    const response = await API.post('/subscriptions/cancel');
    return response.data;
  } catch (error) {
    console.error('Error cancelling subscription:', error);
    throw error;
  }
};

export const deleteAccount = async () => {
  try {
    const response = await API.delete('/users/me');
    return response.data;
  } catch (error) {
    console.error('Error deleting account:', error);
    throw error;
  }
};

export const getNotificationSettings = async () => {
  try {
    const response = await API.get('/users/notification-settings');
    return response.data;
  } catch (error) {
    console.error('Error getting notification settings:', error);
    throw error;
  }
};

export const updateNotificationSettings = async (settings) => {
  try {
    const response = await API.put('/users/notification-settings', settings);
    return response.data;
  } catch (error) {
    console.error('Error updating notification settings:', error);
    throw error;
  }
};

export const updatePrivacySettings = async (settings) => {
  try {
    const response = await API.put('/users/privacy-settings', settings);
    return response.data;
  } catch (error) {
    console.error('Error updating privacy settings:', error);
    throw error;
  }
};

export const requestDataDownload = async () => {
  try {
    const response = await API.post('/users/data-download');
    return response.data;
  } catch (error) {
    console.error('Error requesting data download:', error);
    throw error;
  }
};

export const updateUsername = async (newUsername) => {
  try {
    const response = await API.put('/users/username', { username: newUsername });
    return response.data;
  } catch (error) {
    console.error('Error updating username:', error);
    throw error;
  }
};

export const updatePassword = async (currentPassword, newPassword) => {
  try {
    const response = await API.put('/users/password', { currentPassword, newPassword });
    return response.data;
  } catch (error) {
    console.error('Error updating password:', error);
    throw error;
  }
};

export const toggleTwoFactorAuth = async (enable) => {
  try {
    const response = await API.put('/users/two-factor-auth', { enable });
    return response.data;
  } catch (error) {
    console.error('Error toggling two-factor authentication:', error);
    throw error;
  }
};

export const loginWithGoogle = async (token) => {
  try {
    const response = await API.post('/auth/google', { token });
    return response.data;
  } catch (error) {
    console.error('Error logging in with Google:', error);
    throw error;
  }
};

export const getNotifications = async () => {
  try {
    const response = await API.get('/notifications');
    return response.data;
  } catch (error) {
    console.error('Error getting notifications:', error);
    throw error;
  }
};

export const markNotificationAsRead = async (notificationId) => {
  try {
    const response = await API.put(`/notifications/${notificationId}/read`);
    return response.data;
  } catch (error) {
    console.error('Error marking notification as read:', error);
    throw error;
  }
};

export const getPageContent = async (pageName) => {
  try {
    const response = await API.get(`/content/${pageName}`);
    return response.data;
  } catch (error) {
    console.error('Error getting page content:', error);
    throw error;
  }
};

export default API;