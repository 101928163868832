// src/components/TracklistDetails.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Paper, Box, CircularProgress, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Alert, Stack, IconButton, ListItem, ListItemText, Tooltip } from '@mui/material';
import { getTracklistById, saveTracklist, updateTracklist, getTrackPreview, searchSpotifyTrack, handleSpotifyError, isSpotifyConnected, saveTrackToLibrary, removeTrackFromLibrary } from '../services/api';
import API, { initiateSpotifyAuth } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import CreateSpotifyPlaylistButton from './CreateSpotifyPlaylistButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { styled } from '@mui/system';
import { keyframes } from '@mui/system';
import SpotifyLoginConfirmation from './SpotifyLoginConfirmation';

// ... (keep your existing styled components)

const moveShape = keyframes`
  0%, 100% { transform: translate(0, 0) scale(1); }
  50% { transform: translate(-30px, -30px) scale(1.2); }
`;

const AnimatedBackground = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
  '& svg': {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

const Shape = styled('circle')(({ theme }) => ({
  fill: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.05)',
  animation: `${moveShape} 15s infinite ease-in-out`,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(28, 28, 28, 0.8)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: '#fff',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
}));

function SortableTrack({ track, index, isEditing, handleTrackUpdate, handleDeleteTrack, setNotification, handleSpotifyReAuth, handleLike }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: track._id || `track-${index}` });
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(track.previewUrl));

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  const handlePlayPause = () => {
    if (!track.previewUrl) {
      setNotification({
        open: true,
        message: 'No preview available for this track.',
        severity: 'info'
      });
      return;
    }

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSpotifyAuth = async () => {
    try {
      const authUrl = await initiateSpotifyAuth();
      const authWindow = window.open(authUrl, 'Spotify Login', 'width=800,height=600');
      
      window.addEventListener('message', async (event) => {
        if (event.origin !== window.location.origin) return;
        
        if (event.data.type === 'SPOTIFY_AUTH_SUCCESS') {
          authWindow.close();
          // Retry the last action (save or remove track)
          await handleLike();
        }
      }, false);
    } catch (error) {
      console.error('Error initiating Spotify auth:', error);
      setNotification({
        open: true,
        message: 'Failed to authenticate with Spotify. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleLikeClick = () => {
    if (!track.spotifyId) {
      setNotification({
        open: true,
        message: 'Spotify data not available for this track.',
        severity: 'info'
      });
      return;
    }
    handleLike(track.spotifyId, track.isLiked);
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box ref={setNodeRef} style={style} {...attributes} sx={{ mb: 2, p: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
      {isEditing ? (
        <Stack direction="row" spacing={2} alignItems="center">
          <Box {...listeners} sx={{ cursor: 'move', '&:hover': { opacity: 0.7 }, display: 'flex', alignItems: 'center' }}>
            <DragIndicatorIcon sx={{ color: '#fefeff' }} />
          </Box>
          <TextField
            label="Title"
            value={track.title}
            onChange={(e) => handleTrackUpdate(index, 'title', e.target.value)}
            sx={{ input: { color: '#fefeff' } }}
          />
          <TextField
            label="Artist"
            value={track.artist}
            onChange={(e) => handleTrackUpdate(index, 'artist', e.target.value)}
            sx={{ input: { color: '#fefeff' } }}
          />
          <IconButton onClick={() => handleDeleteTrack(index)} sx={{ color: '#e84c88' }}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ) : (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography sx={{ color: '#fefeff' }}>
            {index + 1}. {track.title} - {track.artist}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Tooltip title="Preview">
              <IconButton onClick={handlePlayPause}>
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title={track.isLiked ? "Remove from Liked Songs" : "Save to Liked Songs"}>
              <IconButton onClick={handleLikeClick}>
                {track.isLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      )}
      {track.startTime !== undefined && track.endTime !== undefined && (
        <Typography variant="body2" sx={{ color: '#e9bb3d' }}>
          {formatTime(track.startTime)} - {formatTime(track.endTime)}
        </Typography>
      )}
    </Box>
  );
}

function TracklistDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tracklist, setTracklist] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [tracklistName, setTracklistName] = useState('');
  const { user } = useAuth();
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });
  const [isEditing, setIsEditing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSpotifyConfirmation, setShowSpotifyConfirmation] = useState(false);
  const [pendingSpotifyAction, setPendingSpotifyAction] = useState(null);

  useEffect(() => {
    const fetchTracklist = async () => {
      try {
        setLoading(true);
        const data = await getTracklistById(id);
        setTracklist(data);
        setTracklistName(data.name);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching tracklist:', error);
        setNotification({
          open: true,
          message: 'Failed to load tracklist. Please try again.',
          severity: 'error'
        });
        setLoading(false);
      }
    };

    if (id) {
      fetchTracklist();
    }
  }, [id]);

  const showNotification = (message, severity) => {
    setNotification({ open: true, message, severity });
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleSaveTracklist = async () => {
    try {
      if (!user) {
        throw new Error('User not authenticated');
      }
      setLoading(true);
      const uniqueTracks = removeDuplicateTracks(tracklist.tracks);
      console.log('Saving tracklist with data:', { name: tracklistName, tracks: uniqueTracks, userId: user.userId });
      const savedTracklist = await saveTracklist({
        name: tracklistName,
        tracks: uniqueTracks,
        userId: user.userId
      });
      console.log('Received saved tracklist:', savedTracklist);
      setOpenDialog(false);
      if (savedTracklist && savedTracklist._id) {
        showNotification('Tracklist saved successfully!', 'success');
        navigate('/my-tracklists');
      } else {
        console.error('Saved tracklist has no ID:', savedTracklist);
        throw new Error('Saved tracklist has no ID');
      }
    } catch (error) {
      console.error('Error saving tracklist:', error);
      showNotification(`Failed to save tracklist: ${error.message}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateTracklist = async () => {
    try {
      if (!user) {
        throw new Error('User not authenticated');
      }
      setLoading(true);
      const uniqueTracks = removeDuplicateTracks(tracklist.tracks);
      const updatedTracklist = await updateTracklist(id, { ...tracklist, name: tracklistName, tracks: uniqueTracks });
      setTracklist(updatedTracklist);
      setIsEditing(false);
      showNotification('Tracklist updated successfully!', 'success');
    } catch (error) {
      console.error('Error updating tracklist:', error);
      showNotification(`Failed to update tracklist: ${error.message}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleTrackUpdate = (index, field, value) => {
    const updatedTracks = [...tracklist.tracks];
    updatedTracks[index] = { ...updatedTracks[index], [field]: value };
    setTracklist({ ...tracklist, tracks: updatedTracks });
  };

  const handleDeleteTrack = (index) => {
    const updatedTracks = tracklist.tracks.filter((_, i) => i !== index);
    setTracklist({ ...tracklist, tracks: updatedTracks });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setTracklist((tracklist) => {
        const oldIndex = tracklist.tracks.findIndex((t) => t._id === active.id || `track-${tracklist.tracks.indexOf(t)}` === active.id);
        const newIndex = tracklist.tracks.findIndex((t) => t._id === over.id || `track-${tracklist.tracks.indexOf(t)}` === over.id);
        
        return {
          ...tracklist,
          tracks: arrayMove(tracklist.tracks, oldIndex, newIndex),
        };
      });
    }
  };

  const handleSearch = async () => {
    if (!searchQuery.trim()) return;

    try {
      const results = await searchSpotifyTrack(searchQuery);
      if (results.tracks && results.tracks.items.length > 0) {
        const track = results.tracks.items[0];
        const newTrack = {
          title: track.name,
          artist: track.artists[0].name,
          spotifyId: track.id,
          previewUrl: track.preview_url,
          duration: track.duration_ms / 1000,
          isLiked: false // You might want to check this against the user's liked tracks
        };
        setTracklist(prevTracklist => ({
          ...prevTracklist,
          tracks: [...prevTracklist.tracks, newTrack]
        }));
        setSearchQuery('');
      } else {
        setNotification({
          open: true,
          message: 'No tracks found for your search query.',
          severity: 'info'
        });
      }
    } catch (error) {
      console.error('Error searching for track:', error);
      if (error.message === 'SPOTIFY_AUTH_REQUIRED') {
        handleSpotifyReAuth();
      } else {
        setNotification({
          open: true,
          message: 'Failed to search for track. Please try again.',
          severity: 'error'
        });
      }
    }
  };

  const handleSpotifyReAuth = async () => {
    try {
      const authUrl = await initiateSpotifyAuth();
      const authWindow = window.open(authUrl, 'Spotify Login', 'width=800,height=600');
      
      window.addEventListener('message', async (event) => {
        if (event.origin !== window.location.origin) return;
        
        if (event.data.type === 'SPOTIFY_AUTH_SUCCESS') {
          authWindow.close();
          // Retry the last action (search or like)
          if (pendingSpotifyAction) {
            await pendingSpotifyAction();
          }
        }
      }, false);
    } catch (error) {
      console.error('Error initiating Spotify re-authentication:', error);
      setNotification({
        open: true,
        message: 'Failed to re-authenticate with Spotify. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleSpotifyAction = useCallback((action) => {
    if (!isSpotifyConnected()) {
      setShowSpotifyConfirmation(true);
      setPendingSpotifyAction(() => action);
    } else {
      action();
    }
  }, []);

  const handleLike = useCallback(async (spotifyId, isLiked) => {
    const action = async () => {
      try {
        if (isLiked) {
          await removeTrackFromLibrary(spotifyId);
        } else {
          await saveTrackToLibrary(spotifyId);
        }
        setTracklist(prevTracklist => ({
          ...prevTracklist,
          tracks: prevTracklist.tracks.map(track => 
            track.spotifyId === spotifyId ? { ...track, isLiked: !isLiked } : track
          )
        }));
      } catch (error) {
        console.error('Error saving/removing track:', error);
        if (error.message === 'SPOTIFY_AUTH_REQUIRED') {
          handleSpotifyReAuth();
        } else {
          setNotification({
            open: true,
            message: 'Failed to update track. Please try again.',
            severity: 'error'
          });
        }
      }
    };

    handleSpotifyAction(action);
  }, [handleSpotifyAction, setNotification]);

  const handleSpotifyConfirm = useCallback(async () => {
    setShowSpotifyConfirmation(false);
    try {
      // Implement your Spotify authentication logic here
      if (pendingSpotifyAction) {
        await pendingSpotifyAction();
      }
    } catch (error) {
      console.error('Failed to connect to Spotify:', error);
      setNotification({
        open: true,
        message: 'Failed to connect to Spotify. Please try again.',
        severity: 'error'
      });
    }
  }, [pendingSpotifyAction]);

  // Use this function when you encounter the "Insufficient client scope" error
  if (error && error.message.includes('Insufficient client scope')) {
    handleSpotifyReAuth();
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!tracklist) {
    return <Typography>No tracklist data available</Typography>;
  }

  return (
    <Box sx={{ position: 'relative', minHeight: '100vh' }}>
      <AnimatedBackground>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" preserveAspectRatio="none">
          <Shape cx="500" cy="500" r="250" />
        </svg>
      </AnimatedBackground>
      <Container maxWidth="md" sx={{ pt: 4, position: 'relative', zIndex: 1 }}>
        {tracklist && (
          <>
            <Stack 
              direction="row" 
              justifyContent="space-between" 
              alignItems="center" 
              sx={{ mb: 3 }}
            >
              <Typography variant="h4" sx={{ color: '#fefeff' }}>
                Tracklist Details
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button 
                  onClick={() => setIsEditing(!isEditing)} 
                  variant="contained" 
                  color={isEditing ? "secondary" : "primary"}
                >
                  {isEditing ? "Cancel Editing" : "Edit Tracklist"}
                </Button>
                <CreateSpotifyPlaylistButton tracklist={tracklist} />
              </Stack>
            </Stack>
            <StyledPaper>
              {isEditing ? (
                <TextField
                  fullWidth
                  label="Tracklist Name"
                  value={tracklistName}
                  onChange={(e) => setTracklistName(e.target.value)}
                  sx={{ mb: 2, input: { color: '#fefeff' } }}
                />
              ) : (
                <Typography variant="h5" sx={{ color: '#e9bb3d', mb: 2 }}>
                  {tracklist.name}
                </Typography>
              )}
              <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={tracklist.tracks.map((t, i) => t._id || `track-${i}`)} strategy={verticalListSortingStrategy}>
                  {tracklist.tracks.map((track, index) => (
                    <SortableTrack
                      key={track._id || `track-${index}`}
                      track={track}
                      index={index}
                      isEditing={isEditing}
                      handleTrackUpdate={handleTrackUpdate}
                      handleDeleteTrack={handleDeleteTrack}
                      setNotification={setNotification}
                      handleSpotifyReAuth={handleSpotifyReAuth}
                      handleLike={handleLike}
                    />
                  ))}
                </SortableContext>
              </DndContext>
              {isEditing && (
                <Button onClick={handleUpdateTracklist} variant="contained" color="primary" sx={{ mt: 2 }}>
                  Save Changes
                </Button>
              )}
              <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                <TextField
                  fullWidth
                  label="Search for a track"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                  sx={{ mr: 1 }}
                />
                <Button onClick={handleSearch} variant="contained">Search</Button>
              </Box>
            </StyledPaper>
          </>
        )}
      </Container>
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(33, 32, 35, 0.9)',
            boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
            backdropFilter: 'blur(10px)',
            borderRadius: '10px',
          }
        }}
      >
        <DialogTitle sx={{ color: '#e9bb3d', fontFamily: 'Cinzel, serif' }}>Save Tracklist</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Tracklist Name"
            type="text"
            fullWidth
            variant="outlined"
            value={tracklistName}
            onChange={(e) => setTracklistName(e.target.value)}
            sx={{
              input: { color: 'white' },
              label: { color: '#e9bb3d' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#e9bb3d',
                },
                '&:hover fieldset': {
                  borderColor: '#e84c88',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#e84c88',
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} sx={{ color: '#e9bb3d' }}>Cancel</Button>
          <Button onClick={handleSaveTracklist} sx={{ color: '#e84c88' }}>Save</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleCloseNotification}>
        <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
      <SpotifyLoginConfirmation
        open={showSpotifyConfirmation}
        onClose={() => setShowSpotifyConfirmation(false)}
        onConfirm={handleSpotifyConfirm}
      />
    </Box>
  );
}

// Helper functions remain unchanged
function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}

function removeDuplicateTracks(tracks) {
  const uniqueTracks = [];
  const seenTracks = new Set();

  for (const track of tracks) {
    const trackKey = `${track.title}-${track.artist}`;
    if (!seenTracks.has(trackKey)) {
      seenTracks.add(trackKey);
      uniqueTracks.push(track);
    }
  }

  return uniqueTracks;
}

export default TracklistDetails;