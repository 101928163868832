import React, { useState } from 'react';
import { Container, Typography, Button, TextField, Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { analyzeMix } from '../services/api';
import Notification from '../components/Notification';
import AnalysisInProgressModal from '../components/AnalysisInProgressModal';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinkIcon from '@mui/icons-material/Link';
import { keyframes } from '@mui/system';

const StyledPaper = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(28, 28, 28, 0.8)',
  borderRadius: '16px',
  padding: theme.spacing(4),
  color: '#fff',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 8px 40px rgba(0, 0, 0, 0.2)',
    transform: 'translateY(-5px)',
  },
}));

const GlowingTypography = styled(Typography)(({ theme }) => ({
  color: '#4ECDC4',
  textShadow: '0 0 10px #4ECDC4, 0 0 20px #4ECDC4, 0 0 30px #4ECDC4, 0 0 40px #4ECDC4',
  animation: '$glow 1.5s ease-in-out infinite alternate',
  '@keyframes glow': {
    'from': {
      textShadow: '0 0 5px #4ECDC4, 0 0 10px #4ECDC4, 0 0 15px #4ECDC4, 0 0 20px #4ECDC4',
    },
    'to': {
      textShadow: '0 0 10px #4ECDC4, 0 0 20px #4ECDC4, 0 0 30px #4ECDC4, 0 0 40px #4ECDC4, 0 0 50px #4ECDC4',
    },
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: '#fff',
  borderRadius: '12px',
  padding: '10px 20px',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    transform: 'translateY(-2px)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#4ECDC4',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputBase-input': {
    color: '#fff',
  },
}));

const moveShape = keyframes`
  0%, 100% { transform: translate(0, 0) scale(1); }
  50% { transform: translate(-30px, -30px) scale(1.2); }
`;

const AnimatedBackground = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
  '& svg': {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

const Shape = styled('circle')(({ theme }) => ({
  fill: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.05)',
  animation: `${moveShape} 15s infinite ease-in-out`,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: '#4ECDC4',
  fontWeight: 'bold',
  textAlign: 'center',
  fontFamily: '"Poppins", sans-serif',
}));

function AnalyzeMix() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [inputType, setInputType] = useState('file');
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });
  const [tracklistName, setTracklistName] = useState('');
  const [progress, setProgress] = useState({ message: '', percent: 0 });
  const [showAnalysisModal, setShowAnalysisModal] = useState(false);

  const handleInputTypeChange = (event) => {
    setInputType(event.target.value);
    setFile(null);
    setUrl('');
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleTracklistNameChange = (event) => {
    setTracklistName(event.target.value);
  };

  const handleAnalyzeMix = async (input, tracklistName) => {
    try {
      const updateProgress = (message, percent) => {
        setProgress({ message, percent });
      };

      const result = await analyzeMix(input, tracklistName, updateProgress);
      // Handle the result...
    } catch (error) {
      console.error('Error analyzing mix:', error);
      // Handle the error...
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!user) {
      setNotification({ open: true, message: 'Please log in to analyze a mix', severity: 'error' });
      return;
    }
    if (!tracklistName.trim()) {
      setNotification({ open: true, message: 'Please enter a name for your tracklist', severity: 'error' });
      return;
    }
    setIsLoading(true);

    try {
      let inputValue = inputType === 'file' ? file : url;
      if (!inputValue) {
        throw new Error(`Please ${inputType === 'file' ? 'select a file' : 'enter a valid URL'}`);
      }

      const response = await analyzeMix(inputValue, tracklistName);

      if (response && response.analysisId) {
        setShowAnalysisModal(true);
        setNotification({ 
          open: true, 
          message: 'Analysis started successfully. You will be notified when it\'s complete.', 
          severity: 'success' 
        });
      } else {
        throw new Error('No analysis ID received from the server');
      }

    } catch (error) {
      console.error('Error analyzing mix:', error);
      const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
      setNotification({ open: true, message: `Error analyzing mix: ${errorMessage}`, severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ 
      display: 'flex',
      minHeight: '100vh',
    }}>
      <AnimatedBackground>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" preserveAspectRatio="none">
          <Shape cx="500" cy="500" r="250" />
        </svg>
      </AnimatedBackground>
      <Container maxWidth="md" sx={{ 
        position: 'relative', 
        zIndex: 2, 
        py: 4,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}>
        <StyledPaper sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <StyledTypography
            variant="h2"
            sx={{ mb: 4 }}
          >
            Analyze Mix
          </StyledTypography>
          <form onSubmit={onSubmit}>
            <FormControl component="fieldset" sx={{ mb: 3, width: '100%' }}>
              <FormLabel component="legend" sx={{ color: '#4ECDC4', mb: 2 }}>Choose input type</FormLabel>
              <RadioGroup
                aria-label="input-type"
                name="input-type"
                value={inputType}
                onChange={handleInputTypeChange}
                sx={{ flexDirection: 'row', justifyContent: 'center' }}
              >
                <FormControlLabel value="file" control={<Radio sx={{ color: '#4ECDC4' }} />} label="File Upload" sx={{ color: '#fff' }} />
                <FormControlLabel value="url" control={<Radio sx={{ color: '#4ECDC4' }} />} label="URL" sx={{ color: '#fff' }} />
              </RadioGroup>
            </FormControl>

            {inputType === 'file' && (
              <Box sx={{ mb: 3, textAlign: 'center' }}>
                <input
                  accept="audio/*"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="raised-button-file">
                  <ActionButton component="span" startIcon={<CloudUploadIcon />}>
                    Upload Audio File
                  </ActionButton>
                </label>
                {file && <Typography sx={{ mt: 2, color: '#4ECDC4' }}>{file.name}</Typography>}
              </Box>
            )}

            {inputType === 'url' && (
              <StyledTextField
                fullWidth
                label="Enter URL (YouTube or SoundCloud)"
                value={url}
                onChange={handleUrlChange}
                sx={{ mb: 3 }}
                InputProps={{
                  startAdornment: <LinkIcon sx={{ color: '#4ECDC4', mr: 1 }} />,
                }}
              />
            )}

            <StyledTextField
              fullWidth
              label="Tracklist Name"
              value={tracklistName}
              onChange={handleTracklistNameChange}
              sx={{ mb: 3 }}
            />

            <Box sx={{ textAlign: 'center' }}>
              <ActionButton 
                type="submit" 
                disabled={isLoading || (inputType === 'file' && !file) || (inputType === 'url' && !url) || !tracklistName.trim()}
                sx={{ 
                  backgroundColor: '#4ECDC4',
                  '&:hover': { backgroundColor: '#FF6B6B' },
                  '&:disabled': { backgroundColor: 'rgba(255, 255, 255, 0.12)', color: 'rgba(255, 255, 255, 0.3)' },
                }}
              >
                {isLoading ? 'Analyzing...' : 'Analyze Mix'}
              </ActionButton>
            </Box>
          </form>
        </StyledPaper>
      </Container>
      <Notification
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        onClose={() => setNotification({ ...notification, open: false })}
      />
      <AnalysisInProgressModal 
        open={showAnalysisModal} 
        onClose={() => {
          setShowAnalysisModal(false);
          navigate('/dashboard'); // Or wherever you want to redirect the user
        }} 
      />
    </Box>
  );
}

export default AnalyzeMix;