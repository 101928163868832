import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Avatar, Typography, Box, useTheme, Collapse, Switch } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { useColorMode } from '../contexts/ColorModeContext';
import DashboardIcon from '@mui/icons-material/Dashboard';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import StarIcon from '@mui/icons-material/Star';
import ExploreIcon from '@mui/icons-material/Explore';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SecurityIcon from '@mui/icons-material/Security';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DeleteIcon from '@mui/icons-material/Delete';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Notifications from './Notifications';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: 240,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 240,
    boxSizing: 'border-box',
    backgroundColor: 'rgba(28, 28, 28, 0.9)',
    color: '#fff',
    borderRight: '1px solid rgba(255, 255, 255, 0.12)',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: '8px',
  margin: '4px 8px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontWeight: 500,
    fontSize: '0.95rem',
    color: '#fff',
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  minWidth: 40,
}));

const UserInfo = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
  marginBottom: theme.spacing(2),
}));

const UserName = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1rem',
  color: '#fff',
}));

const Sidebar = ({ user, onLogout }) => {
  const theme = useTheme();
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const { toggleColorMode, mode } = useColorMode();

  const handleSettingsClick = () => {
    setSettingsOpen(!settingsOpen);
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
    { text: 'My Tracklists', icon: <QueueMusicIcon />, path: '/my-tracklists' },
    { text: 'Analyze Mix', icon: <AddIcon />, path: '/analyze-mix' },
    { text: 'Recent Analyses', icon: <HistoryIcon />, path: '/recent-analyses' },
    { text: 'Favorites', icon: <StarIcon />, path: '/favorites' },
    { text: 'Explore', icon: <ExploreIcon />, path: '/explore' },
    { text: 'Billing', icon: <CreditCardIcon />, path: '/billing' },
    { text: 'Help & Support', icon: <HelpIcon />, path: '/help' },
  ];

  const settingsItems = [
    { text: 'Profile', icon: <AccountCircleIcon />, path: '/settings/profile' },
    { text: 'Security', icon: <SecurityIcon />, path: '/settings/security' },
    { text: 'Privacy', icon: <PrivacyTipIcon />, path: '/settings/privacy' },
    { text: 'Notifications', icon: <NotificationsIcon />, path: '/settings/notifications' },
    { text: 'Delete Account', icon: <DeleteIcon />, path: '/settings/delete-account' },
  ];

  // Add a check for user object
  if (!user) {
    return null; // or return a loading indicator
  }

  return (
    <StyledDrawer variant="permanent" anchor="left">
      <UserInfo>
        <Avatar src={user.avatar} alt={user.username} />
        <UserName>{user.username}</UserName>
        <Notifications />
      </UserInfo>
      <List>
        {menuItems.map((item) => (
          <StyledListItem button key={item.text} component={Link} to={item.path}>
            <StyledListItemIcon>{item.icon}</StyledListItemIcon>
            <StyledListItemText primary={item.text} />
          </StyledListItem>
        ))}
        <StyledListItem button onClick={handleSettingsClick}>
          <StyledListItemIcon><SettingsIcon /></StyledListItemIcon>
          <StyledListItemText primary="Settings" />
          {settingsOpen ? <ExpandLess /> : <ExpandMore />}
        </StyledListItem>
        <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {settingsItems.map((item) => (
              <StyledListItem button key={item.text} component={Link} to={item.path} sx={{ pl: 4 }}>
                <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                <StyledListItemText primary={item.text} />
              </StyledListItem>
            ))}
          </List>
        </Collapse>
        <StyledListItem>
          <StyledListItemIcon>
            {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </StyledListItemIcon>
          <Switch checked={mode === 'dark'} onChange={toggleColorMode} />
        </StyledListItem>
        <StyledListItem button onClick={onLogout}>
          <StyledListItemIcon sx={{ color: '#FF6B6B' }}><LogoutIcon /></StyledListItemIcon>
          <StyledListItemText primary="Logout" />
        </StyledListItem>
      </List>
    </StyledDrawer>
  );
};

export default Sidebar;