import React from 'react';
import { Badge, IconButton, Menu, MenuItem, Typography, CircularProgress, useTheme } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

function Notifications() {
  const { notifications, markNotificationAsRead, isLoading } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (notification) => {
    markNotificationAsRead(notification.id);
    handleClose();
    if (notification.type === 'tracklist_ready') {
      navigate(`/tracklist/${notification.tracklistId}`);
    }
  };

  const unreadCount = Array.isArray(notifications) 
    ? notifications.filter(n => !n.read).length 
    : 0;

  const notificationColor = theme.palette.mode === 'dark' ? '#4ECDC4' : '#000000';

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={unreadCount} color="secondary">
          <NotificationsIcon sx={{ color: theme.palette.primary.main }} />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          },
        }}
      >
        {isLoading ? (
          <MenuItem>
            <CircularProgress size={20} sx={{ color: theme.palette.primary.main }} />
            <Typography sx={{ ml: 1 }}>Loading notifications...</Typography>
          </MenuItem>
        ) : !Array.isArray(notifications) || notifications.length === 0 ? (
          <MenuItem>
            <Typography>No notifications</Typography>
          </MenuItem>
        ) : (
          notifications.map((notification) => (
            <MenuItem
              key={notification.id}
              onClick={() => handleNotificationClick(notification)}
              sx={{
                backgroundColor: notification.read ? 'transparent' : theme.palette.action.hover,
                '&:hover': { backgroundColor: theme.palette.action.selected },
              }}
            >
              <Typography>{notification.message}</Typography>
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
}

export default Notifications;