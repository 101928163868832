// frontend/src/components/AccountSettings/SecuritySection.js
import React, { useState } from 'react';
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import { updatePassword, toggleTwoFactorAuth, deleteAccount } from '../../services/api';

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  borderColor: theme.palette.common.white,
  '&:hover': {
    borderColor: theme.palette.common.white,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

function SecuritySection({ userProfile, setUserProfile }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(userProfile?.twoFactorEnabled || false);
  const [password, setPassword] = useState('');
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setSnackbar({ open: true, message: 'New passwords do not match', severity: 'error' });
      return;
    }
    try {
      await updatePassword(currentPassword, newPassword);
      handleCloseDialog();
      setSnackbar({ open: true, message: 'Password updated successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || 'An error occurred',
        severity: 'error',
      });
    }
  };

  const handleToggleTwoFactor = async () => {
    try {
      const result = await toggleTwoFactorAuth(!twoFactorEnabled);
      setTwoFactorEnabled(result.twoFactorEnabled);
      setSnackbar({
        open: true,
        message: `Two-factor authentication ${result.twoFactorEnabled ? 'enabled' : 'disabled'}`,
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.message || 'An error occurred',
        severity: 'error',
      });
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteAccount(password);
      setDeleteAccountOpen(false);
      setSnackbar({ open: true, message: 'Account deleted successfully', severity: 'success' });
      // Implement logout and redirect to home page
    } catch (error) {
      setSnackbar({ open: true, message: error.response?.data?.message || 'Error deleting account', severity: 'error' });
    }
  };

  return (
    <Box sx={{ color: 'common.white' }}>
      <Typography variant="h5" gutterBottom>
        Security Settings
      </Typography>
      
      <List>
        <ListItem>
          <ListItemText 
            primary="Password" 
            secondary="Last changed: Never" 
            secondaryTypographyProps={{ sx: { color: 'rgba(255, 255, 255, 0.7)' } }}
          />
          <ListItemSecondaryAction>
            <StyledButton variant="outlined" onClick={handleOpenDialog}>
              Change Password
            </StyledButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
        <ListItem>
          <ListItemText 
            primary="Two-Factor Authentication" 
            secondary={twoFactorEnabled ? "Enabled" : "Disabled"}
            secondaryTypographyProps={{ sx: { color: 'rgba(255, 255, 255, 0.7)' } }}
          />
          <ListItemSecondaryAction>
            <FormControlLabel
              control={
                <Switch
                  checked={twoFactorEnabled}
                  onChange={handleToggleTwoFactor}
                  color="primary"
                />
              }
              label=""
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Current Password"
            type="password"
            fullWidth
            variant="outlined"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            label="New Password"
            type="password"
            fullWidth
            variant="outlined"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Confirm New Password"
            type="password"
            fullWidth
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Update Password
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteAccountOpen} onClose={() => setDeleteAccountOpen(false)}>
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your account? This action cannot be undone. Please enter your password to confirm.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteAccountOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteAccount} color="error">
            Delete Account
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default SecuritySection;