// src/pages/Dashboard.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Stack, Container, CircularProgress, LinearProgress, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { PlayArrow as PlayArrowIcon, Edit as EditIcon, PlaylistAdd as PlaylistAddIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getUserTracklists } from '../services/api';
import Notification from '../components/Notification';
import { keyframes } from '@mui/system';
import { styled, ThemeProvider } from '@mui/material/styles';
import Lottie from 'react-lottie';
import levelUpAnimation from '../animations/level-up.json';
import Sidebar from '../components/Sidebar';
import { useTheme } from '@mui/material/styles';

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: { main: '#ff3131' },
          secondary: { main: '#000000' },
          background: { default: '#f0f0f0', paper: '#ffffff' },
          text: { primary: '#000000', secondary: '#555555' },
        }
      : {
          primary: { main: '#4ECDC4' },
          secondary: { main: '#ffffff' },
          background: { default: '#121212', paper: '#1e1e1e' },
          text: { primary: '#ffffff', secondary: '#b0b0b0' },
        }),
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h3: { fontWeight: 800, letterSpacing: '-0.02em' },
    h5: { fontWeight: 700, letterSpacing: '-0.01em' },
    subtitle1: { fontWeight: 600 },
    body1: { fontWeight: 400 },
    body2: { fontWeight: 400 },
  },
});

const moveShape = keyframes`
  0%, 100% { transform: translate(0, 0) scale(1); }
  50% { transform: translate(-30px, -30px) scale(1.2); }
`;

const AnimatedBackground = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
  '& svg': {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

const Shape = styled('circle')(({ theme }) => ({
  fill: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.05)',
  animation: `${moveShape} 15s infinite ease-in-out`,
}));

const WelcomeBox = styled(Box)(({ theme }) => ({
  background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(30, 30, 30, 0.9)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  boxShadow: theme.palette.mode === 'light' 
    ? '0 8px 32px rgba(0, 0, 0, 0.1)' 
    : '0 8px 32px rgba(0, 0, 0, 0.3)',
  backdropFilter: 'blur(10px)',
  border: theme.palette.mode === 'light'
    ? '2px solid rgba(255, 49, 49, 0.5)'
    : '2px solid rgba(78, 205, 196, 0.5)',
  position: 'relative',
  overflow: 'hidden',
}));

const WelcomeTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#FF6B6B' : '#4ECDC4',
  fontWeight: 800,
  textAlign: 'center',
  letterSpacing: '-0.02em',
  textTransform: 'uppercase',
  textShadow: theme.palette.mode === 'light'
    ? '2px 2px 4px rgba(0,0,0,0.1)'
    : '2px 2px 4px rgba(0,0,0,0.3)',
}));

const StatCard = styled(Paper)(({ theme }) => ({
  background: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.9)' : 'rgba(30, 30, 30, 0.9)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  boxShadow: theme.palette.mode === 'light'
    ? '0 8px 32px rgba(0, 0, 0, 0.1)'
    : '0 8px 32px rgba(0, 0, 0, 0.3)',
  backdropFilter: 'blur(10px)',
  border: theme.palette.mode === 'light'
    ? '1px solid rgba(0, 0, 0, 0.1)'
    : '1px solid rgba(255, 255, 255, 0.1)',
  position: 'relative',
  overflow: 'hidden',
}));

const StatBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  borderRadius: '12px',
  background: theme.palette.mode === 'light'
    ? 'linear-gradient(135deg, rgba(78, 205, 196, 0.2) 0%, rgba(85, 98, 112, 0.2) 100%)'
    : 'linear-gradient(135deg, rgba(78, 205, 196, 0.1) 0%, rgba(85, 98, 112, 0.1) 100%)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.palette.mode === 'light'
      ? '0 5px 15px rgba(0,0,0,0.1)'
      : '0 5px 15px rgba(0,0,0,0.3)',
  },
}));

const StatsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
}));

function Dashboard() {
  const { user, logout } = useAuth();
  const [tracklists, setTracklists] = useState([]);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });
  const [showLevelUpAnimation, setShowLevelUpAnimation] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          setLoading(true);
          const userTracklists = await getUserTracklists();
          const mockStats = {
            level: 5,
            currentXP: 3500,
            nextLevelXP: 5000,
            trackIDs: 1250,
            generatedPlaylists: 45,
            discoveredArtists: 320,
          };
          setTracklists(userTracklists);
          setStats(mockStats);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setNotification({ open: true, message: 'Error fetching dashboard data', severity: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  if (!user || loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const xpProgress = (stats.currentXP / stats.nextLevelXP) * 100;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        {user && <Sidebar user={user} onLogout={logout} />}
        <Box component="main" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ 
            flexGrow: 1,
            position: 'relative',
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
          }}>
            <AnimatedBackground>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" preserveAspectRatio="none">
                <Shape cx="500" cy="500" r="250" />
              </svg>
            </AnimatedBackground>
            <Container maxWidth="md" sx={{ position: 'relative', zIndex: 2, py: 8 }}>
              {showLevelUpAnimation && (
                <Box sx={{ position: 'fixed', top: '25%', left: '50%', transform: 'translate(-50%, -25%)', zIndex: 1000 }}>
                  <Lottie 
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: levelUpAnimation,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                      }
                    }}
                    height={300}
                    width={300}
                  />
                </Box>
              )}
              <Stack spacing={4}>
                <WelcomeBox>
                  <WelcomeTypography variant="h3">
                    Welcome back, {user.username || 'Mix Master'}!
                  </WelcomeTypography>
                </WelcomeBox>

                {/* User Level and XP */}
                <StatCard>
                  <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>Level {stats.level}</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>Experience Points (XP)</Typography>
                      <LinearProgress 
                        variant="determinate" 
                        value={xpProgress} 
                        sx={{ 
                          height: 10, 
                          borderRadius: 5,
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: '#FF6B6B'
                          }
                        }}
                      />
                      <Typography variant="body2" sx={{ mt: 1, color: '#FF6B6B' }}>
                        {stats.currentXP} / {stats.nextLevelXP} XP
                      </Typography>
                    </Box>
                  </Box>
                </StatCard>

                {/* Stats Section */}
                <StatCard>
                  <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>Your Stats</Typography>
                  <StatsContainer>
                    <StatBox>
                      <Typography variant="h4" sx={{ color: '#4ECDC4', fontWeight: 700 }}>{stats.trackIDs}</Typography>
                      <Typography variant="subtitle2">Track IDs</Typography>
                    </StatBox>
                    <StatBox>
                      <Typography variant="h4" sx={{ color: '#FF6B6B', fontWeight: 700 }}>{stats.generatedPlaylists}</Typography>
                      <Typography variant="subtitle2">Generated Playlists</Typography>
                    </StatBox>
                    <StatBox>
                      <Typography variant="h4" sx={{ color: '#45B7D1', fontWeight: 700 }}>{stats.discoveredArtists}</Typography>
                      <Typography variant="subtitle2">Discovered Artists</Typography>
                    </StatBox>
                  </StatsContainer>
                </StatCard>

                {/* Recent Activity */}
                <StatCard>
                  <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>Recent Activity</Typography>
                  <List>
                    {tracklists.slice(0, 3).map((tracklist) => (
                      <ListItem
                        key={tracklist._id}
                        secondaryAction={
                          <Box>
                            <IconButton component={Link} to={`/tracklist/${tracklist._id}`}>
                              <PlayArrowIcon />
                            </IconButton>
                            <IconButton component={Link} to={`/tracklist/${tracklist._id}/edit`}>
                              <EditIcon />
                            </IconButton>
                            <IconButton>
                              <PlaylistAddIcon />
                            </IconButton>
                          </Box>
                        }
                      >
                        <ListItemText
                          primary={<Typography variant="subtitle1">{tracklist.name}</Typography>}
                          secondary={
                            <Typography variant="body2">
                              Created: {new Date(tracklist.createdAt).toLocaleDateString()} | Tracks: {tracklist.tracks.length}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </StatCard>
              </Stack>
              
              <Notification
                open={notification.open}
                message={notification.message}
                severity={notification.severity}
              />
            </Container>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Dashboard;