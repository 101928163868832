// src/contexts/AuthContext.js

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { checkTokenExpiration } from '../utils/tokenManager';
import { getNotifications, markNotificationAsRead as apiMarkNotificationAsRead, getNotificationEvents } from '../services/api';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [notifications, setNotifications] = useState([]); // Initialize as an empty array
  const [isLoading, setIsLoading] = useState(true);

  const fetchNotifications = useCallback(async () => {
    if (!currentUser) return;
    try {
      const fetchedNotifications = await getNotifications();
      setNotifications(fetchedNotifications);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setNotifications([]);
    }
  }, [currentUser]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && checkTokenExpiration(token)) {
      const userId = localStorage.getItem('userId');
      const username = localStorage.getItem('username');
      const role = localStorage.getItem('role');
      if (userId && username) {
        setCurrentUser({ userId, username, role });
      } else {
        localStorage.clear();
      }
    } else {
      localStorage.clear();
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchNotifications();
    }
  }, [currentUser, fetchNotifications]);

  useEffect(() => {
    if (!currentUser) return;

    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found for SSE connection');
      return;
    }

    const eventSource = new EventSource(`${process.env.REACT_APP_API_BASE_URL}/notifications/events?token=${encodeURIComponent(token)}`);

    eventSource.onopen = () => {
      console.log('SSE connection opened');
    };

    eventSource.onerror = (error) => {
      console.error('SSE error:', error);
      eventSource.close();
    };

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('SSE message received:', data);
      // Handle the message...
    };

    return () => {
      console.log('Closing SSE connection');
      eventSource.close();
    };
  }, [currentUser]);

  const markNotificationAsRead = async (notificationId) => {
    try {
      await apiMarkNotificationAsRead(notificationId);
      setNotifications(prevNotifications =>
        prevNotifications.map(notification =>
          notification.id === notificationId ? { ...notification, read: true } : notification
        )
      );
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const login = useCallback(
    (userData) => {
      console.log('Login function called in AuthContext with:', userData);
      const { token, userId, username, spotifyId } = userData;
      if (!token || !userId) {
        console.error('Invalid login data received:', userData);
        return;
      }
      const user = {
        id: userId,
        username: username || `user_${userId.slice(-5)}`,
        spotifyId,
      };
      setCurrentUser(user);
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      if (username) localStorage.setItem('username', username);
      if (spotifyId) localStorage.setItem('spotifyId', spotifyId);
      fetchNotifications();
    },
    [fetchNotifications]
  );

  const logout = () => {
    console.log('Logout function called');
    setCurrentUser(null);
    localStorage.clear();
    setNotifications([]);
  };

  const value = {
    user: currentUser,
    login,
    logout,
    notifications,
    fetchNotifications,
    markNotificationAsRead,
    isLoading,
  };

  console.log('AuthProvider rendering with currentUser:', currentUser);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;