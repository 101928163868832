   // frontend/src/pages/AnalysisInProgress.js

   import React, { useEffect, useState } from 'react';
   import { Box, Container, Typography, LinearProgress } from '@mui/material';
   import { useParams, useNavigate } from 'react-router-dom';
   import Notification from '../components/Notification';

   function AnalysisInProgress() {
     const { analysisId } = useParams();
     const navigate = useNavigate();
     const [message, setMessage] = useState('Starting analysis...');
     const [progress, setProgress] = useState(0);
     const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });

     useEffect(() => {
       const token = localStorage.getItem('token');
       const eventSource = new EventSource(`${process.env.REACT_APP_API_BASE_URL}/audio/analysis-progress/${analysisId}?token=${encodeURIComponent(token)}`);

       eventSource.onmessage = (event) => {
         const data = JSON.parse(event.data);
         setMessage(data.message);
         setProgress(data.progress);

         if (data.status === 'complete') {
           eventSource.close();
           setNotification({ open: true, message: 'Analysis complete! Your tracklist is ready.', severity: 'success' });
           navigate(`/tracklist/${data.result.tracklistId}`);
         } else if (data.status === 'error') {
           eventSource.close();
           setNotification({ open: true, message: `Error: ${data.message}. Please try again.`, severity: 'error' });
           setTimeout(() => navigate('/analyze-mix'), 5000);
         }
       };

       eventSource.onerror = (error) => {
         console.error('EventSource failed:', error);
         eventSource.close();
         setNotification({ open: true, message: 'Connection lost. Please try again later.', severity: 'error' });
         setTimeout(() => navigate('/analyze-mix'), 5000);
       };

       return () => {
         eventSource.close();
       };
     }, [analysisId, navigate]);

     return (
       <Box sx={{ 
         position: 'relative',
         backgroundColor: 'transparent', 
         minHeight: '100vh',
       }}>
         <Box sx={{
           position: 'absolute',
           top: 0,
           left: 0,
           right: 0,
           bottom: 0,
           backgroundColor: 'rgba(0, 0, 0, 0.7)',
           zIndex: 1
         }} />
         <Container maxWidth="md" sx={{ position: 'relative', zIndex: 2, pt: 12, pb: 8 }}>
           <Typography variant="h2" gutterBottom sx={{ color: '#e9bb3d', fontFamily: 'Cinzel, serif' }}>
             Analysis In Progress
           </Typography>
           <Typography sx={{ color: '#fefeff', mb: 2 }}>
             {message}
           </Typography>
           <LinearProgress variant="determinate" value={progress} sx={{ height: 10, borderRadius: 5 }} />
         </Container>
         <Notification
           open={notification.open}
           message={notification.message}
           severity={notification.severity}
           onClose={() => setNotification({ ...notification, open: false })}
         />
       </Box>
     );
   }

   export default AnalysisInProgress;