// frontend/src/pages/RecentAnalyses.js
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Skeleton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getUserTracklists } from '../services/api';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { styled } from '@mui/system';
import { keyframes } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(28, 28, 28, 0.8)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  color: '#fff',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 8px 40px rgba(0, 0, 0, 0.2)',
    transform: 'translateY(-5px)',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: '#4ECDC4',
  fontWeight: 'bold',
  textAlign: 'center',
  fontFamily: '"Poppins", sans-serif',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: '#4ECDC4',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    color: '#FF6B6B',
    transform: 'scale(1.1)',
  },
}));

const moveShape = keyframes`
  0%, 100% { transform: translate(0, 0) scale(1); }
  50% { transform: translate(-30px, -30px) scale(1.2); }
`;

const AnimatedBackground = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
  '& svg': {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

const Shape = styled('circle')(({ theme }) => ({
  fill: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.05)',
  animation: `${moveShape} 15s infinite ease-in-out`,
}));

function RecentAnalyses() {
  const { user } = useAuth();
  const [tracklists, setTracklists] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTracklists = async () => {
      if (user) {
        try {
          setLoading(true);
          const userTracklists = await getUserTracklists();
          setTracklists(userTracklists);
        } catch (error) {
          console.error('Error fetching tracklists:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchTracklists();
  }, [user]);

  return (
    <Box sx={{ 
      display: 'flex',
      minHeight: '100vh',
    }}>
      <AnimatedBackground>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" preserveAspectRatio="none">
          <Shape cx="500" cy="500" r="250" />
        </svg>
      </AnimatedBackground>
      <Container maxWidth="md" sx={{ 
        position: 'relative', 
        zIndex: 2, 
        py: 4,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}>
        <StyledPaper sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <StyledTypography variant="h2" sx={{ mb: 4 }}>
            Recent Analyses
          </StyledTypography>
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height={400} />
          ) : tracklists.length === 0 ? (
            <Typography>No recent analyses found.</Typography>
          ) : (
            <List>
              {tracklists.map((tracklist) => (
                <ListItem
                  key={tracklist._id}
                  sx={{
                    backgroundColor: 'rgba(33, 32, 35, 0.5)',
                    borderRadius: 2,
                    mb: 2,
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="h6" sx={{ color: '#fff' }}>
                        {tracklist.name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                        Created: {new Date(tracklist.createdAt).toLocaleDateString()} | Tracks: {tracklist.tracks.length}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <StyledIconButton
                      component={Link}
                      to={`/tracklist/${tracklist._id}`}
                      aria-label="view"
                    >
                      <VisibilityIcon />
                    </StyledIconButton>
                    <StyledIconButton
                      component={Link}
                      to={`/tracklist/${tracklist._id}/edit`}
                      aria-label="edit"
                    >
                      <EditIcon />
                    </StyledIconButton>
                    <StyledIconButton aria-label="play">
                      <PlaylistPlayIcon />
                    </StyledIconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </StyledPaper>
      </Container>
    </Box>
  );
}

export default RecentAnalyses;