import React from 'react';
import { Container, Typography, Box, Paper, Grid, Button } from '@mui/material';
import { motion } from 'framer-motion';

const PricingTier = ({ title, price, features, buttonText, recommended }) => (
  <motion.div whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 300, damping: 10 }}>
    <Paper elevation={3} sx={{ 
      p: 4,
      bgcolor: recommended ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.15)',
      color: '#FFFFFF', 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      overflow: 'hidden',
    }}>
      {recommended && (
        <Box sx={{
          position: 'absolute',
          top: '10px',
          right: '-30px',
          transform: 'rotate(45deg)',
          backgroundColor: '#e84c88',
          padding: '5px 40px',
          fontSize: '0.8rem',
          fontWeight: 'bold',
        }}>
          RECOMMENDED
        </Box>
      )}
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', mb: 3 }}>
        {price}
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        {features.map((feature, index) => (
          <Typography key={index} variant="body1" sx={{ mb: 1 }}>
            • {feature}
          </Typography>
        ))}
      </Box>
      <Button 
        variant="contained" 
        sx={{ 
          mt: 3, 
          backgroundColor: '#e84c88',
          '&:hover': { backgroundColor: '#e9bb3d' },
        }}
      >
        {buttonText}
      </Button>
    </Paper>
  </motion.div>
);

function Pricing() {
  return (
    <Box sx={{ 
      position: 'relative',
      backgroundColor: 'transparent', 
      minHeight: '100vh'
    }}>
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 1
      }} />
      <Container maxWidth="lg" sx={{ pt: 12, pb: 8, position: 'relative', zIndex: 2 }}>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography variant="h1" gutterBottom sx={{ 
            color: '#FFFFFF', 
            textAlign: 'center', 
            mb: 6,
            textShadow: '0 0 20px rgba(255,255,255,0.7)',
            fontSize: '3.5rem',
          }}>
            Choose Your Plan
          </Typography>
          
          <Grid container spacing={4} sx={{ mb: 6 }}>
            <Grid item xs={12} md={4}>
              <PricingTier 
                title="Free" 
                price="$0/month"
                features={[
                  "Basic track identification",
                  "Limited playlist creation",
                  "Ad-supported experience"
                ]}
                buttonText="Get Started"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PricingTier 
                title="Starter" 
                price="$9.99/month"
                features={[
                  "Advanced track identification",
                  "Unlimited playlist creation",
                  "Ad-free experience",
                  "Offline mode"
                ]}
                buttonText="Choose Plan"
                recommended={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PricingTier 
                title="Crate Digger" 
                price="$19.99/month"
                features={[
                  "Premium track identification",
                  "Unlimited playlist creation",
                  "Ad-free experience",
                  "Offline mode",
                  "Exclusive DJ tools",
                  "Priority support"
                ]}
                buttonText="Go Pro"
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 6, textAlign: 'center' }}>
            <Typography variant="h4" sx={{ 
              color: '#FFFFFF',
              textShadow: '0 0 10px rgba(255,255,255,0.5)'
            }}>
              Start your journey with ID BRO today!
            </Typography>
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
}

export default Pricing;