import React from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useSession } from '../hooks/useSession';
import Sidebar from './Sidebar';
import MainNavbar from './MainNavbar';
import PrivateRoute from './PrivateRoute';
import VerifyEmail from './VerifyEmail';
import Home from '../pages/Home';
import SignUp from '../pages/SignUp';
import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import About from '../pages/About';
import Features from '../pages/Features';
import Pricing from '../pages/Pricing';
import MyTracklists from '../pages/MyTracklists';
import AccountSettings from '../pages/AccountSettings';
import AnalyzeMix from '../pages/AnalyzeMix';
import TracklistDetails from './TracklistDetails';
import AnalysisInProgress from '../pages/AnalysisInProgress';
import SpotifyCallback from './SpotifyCallback';
import RecentAnalyses from '../pages/RecentAnalyses';
import Favorites from '../pages/Favorites';
import HelpSupport from '../pages/HelpSupport';

function AppContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout, notifications, isLoading } = useAuth();
  useSession(() => navigate('/signin'));

  const isHomePage = location.pathname === '/';
  const isAuthPage = ['/signin', '/signup', '/forgot-password'].includes(location.pathname);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {isHomePage && <MainNavbar />}
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {!isHomePage && !isAuthPage && user && (
          <Sidebar user={user} onLogout={logout} notifications={notifications} />
        )}
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: '100%' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/features" element={<Features />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/spotify-callback" element={<SpotifyCallback />} />
            <Route path="/my-tracklists" element={<PrivateRoute><MyTracklists /></PrivateRoute>} />
            <Route path="/account-settings" element={<PrivateRoute><AccountSettings /></PrivateRoute>} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/analyze-mix" element={<PrivateRoute><AnalyzeMix /></PrivateRoute>} />
            <Route path="/analysis-in-progress/:analysisId" element={<AnalysisInProgress />} />
            <Route path="/tracklist/:id" element={<PrivateRoute><TracklistDetails /></PrivateRoute>} />
            <Route path="/recent-analyses" element={<PrivateRoute><RecentAnalyses /></PrivateRoute>} />
            <Route path="/favorites" element={<PrivateRoute><Favorites /></PrivateRoute>} />
            <Route path="/help" element={<PrivateRoute><HelpSupport /></PrivateRoute>} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}

export default AppContent;