import React from 'react';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import backgroundImage from '../assets/backgroundImage3.png'; // Make sure this path is correct

const FeatureItem = ({ title, description }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 400, damping: 10 }}
  >
    <Paper elevation={3} sx={{ 
      p: 4, // Increased padding
      bgcolor: 'rgba(255, 255, 255, 0.15)', // Slightly darker background
      color: '#FFFFFF', 
      height: '100%',
      backdropFilter: 'blur(5px)',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        bgcolor: 'rgba(255, 255, 255, 0.25)', // Darker on hover
        boxShadow: '0 8px 16px 0 rgba(255,255,255,0.2)',
      }
    }}>
      <Typography variant="h5" gutterBottom sx={{ // Increased from h6 to h5
        color: '#FFFFFF', 
        textShadow: '0 0 10px rgba(255,255,255,0.5)',
        fontWeight: 'bold', // Added bold
      }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ color: '#E0E0E0' }}>{description}</Typography> // Changed from body2 to body1
    </Paper>
  </motion.div>
);

function Features() {
  return (
    <Box sx={{ 
      position: 'relative',
      backgroundColor: 'transparent', 
      minHeight: '100vh'
    }}>
      {/* Semi-transparent overlay */}
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Slightly darker overlay
        zIndex: 1
      }} />
      <Container maxWidth="lg" sx={{ pt: 12, pb: 8, position: 'relative', zIndex: 2 }}>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography variant="h1" gutterBottom sx={{ // Changed from h2 to h1
            color: '#FFFFFF', 
            textAlign: 'center', 
            mb: 6,
            textShadow: '0 0 20px rgba(255,255,255,0.7)',
            fontSize: '3.5rem', // Explicitly set font size
          }}>
            Features
          </Typography>
          
          <Grid container spacing={4} sx={{ mb: 6 }}>
            <Grid item xs={12} md={4}>
              <FeatureItem 
                title="AI-Powered Track Identification" 
                description="Our advanced AI algorithms can identify tracks even in noisy environments, making sure you never miss a beat."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureItem 
                title="Seamless Playlist Creation" 
                description="Create and manage playlists on the fly. Add identified tracks directly to your favorite playlists with just a tap."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureItem 
                title="Cross-Platform Integration" 
                description="Connect with popular music platforms like Spotify, SoundCloud, and YouTube to sync your music library and playlists."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureItem 
                title="Community Sharing" 
                description="Share your discoveries with the ID BRO community. See what tracks are trending in real-time at events and clubs."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureItem 
                title="Offline Mode" 
                description="No internet? No problem. ID BRO works offline, storing your identified tracks for later syncing."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FeatureItem 
                title="DJ Tools" 
                description="Special features for DJs including BPM detection, key identification, and seamless integration with popular DJ software."
              />
            </Grid>
          </Grid>

          {/* Image Section */}
          <Box sx={{ mb: 6, display: 'flex', justifyContent: 'center' }}>
            <Box
              component="img"
              src={backgroundImage}
              alt="Feature Background"
              sx={{
                maxWidth: '100%',
                maxHeight: '400px', // Adjust this value as needed
                width: 'auto',
                height: 'auto',
                objectFit: 'contain',
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          </Box>

          <Box sx={{ mt: 6, textAlign: 'center' }}>
            <Typography variant="h4" sx={{ // Changed from h5 to h4
              color: '#FFFFFF',
              textShadow: '0 0 10px rgba(255,255,255,0.5)'
            }}>
              Experience the future of music discovery with ID BRO
            </Typography>
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
}

export default Features;
