import React from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// Create a styled component for the logo
const LogoTypography = styled(Link)(({ theme }) => ({
  fontFamily: "'Orbitron', sans-serif",
  fontSize: '2.5rem',
  fontWeight: 700,
  color: theme.palette.primary.main,
  textDecoration: 'none',
  textShadow: `0 0 10px ${theme.palette.primary.main}`,
  letterSpacing: '3px',
  transition: 'all 0.3s ease',
  display: 'inline-block', 
  transformOrigin: 'left center',  
  '&:hover': {
    textShadow: `0 0 20px ${theme.palette.primary.main}`,
    transform: 'scale(1.05)',
  },
}));

const NavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.1rem',
  marginLeft: theme.spacing(2),
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

function MainNavbar() {
  return (
    <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Toolbar>
        <LogoTypography to="/" sx={{ flexGrow: 1 }}>
          ID BRO
        </LogoTypography>
        <NavButton component={Link} to="/about">About</NavButton>
        <NavButton component={Link} to="/features">Features</NavButton>
        <NavButton component={Link} to="/pricing">Pricing</NavButton>
        <NavButton component={Link} to="/signin">Sign In</NavButton>
        <NavButton
          variant="contained"
          component={Link}
          to="/signup"
          sx={(theme) => ({
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            '&:hover': { backgroundColor: theme.palette.secondary.dark },
          })}
        >
          Sign Up
        </NavButton>
      </Toolbar>
    </AppBar>
  );
}

export default MainNavbar;