// frontend/src/components/CreateSpotifyPlaylistButton.js
import React, { useState } from 'react';
import { Button, Snackbar, Alert } from '@mui/material';
import { createSpotifyPlaylist, searchSpotifyTrack, initiateSpotifyAuth } from '../services/api';

function CreateSpotifyPlaylistButton({ tracklist }) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleCreatePlaylist = async () => {
    try {
      const spotifyTrackIds = await Promise.all(
        tracklist.tracks.map(async (track) => {
          const searchQuery = `${track.title} ${track.artist}`;
          try {
            const searchResult = await searchSpotifyTrack(searchQuery);
            return searchResult.tracks.items[0]?.id;
          } catch (error) {
            if (error.response?.status === 401) {
              const { authUrl } = await initiateSpotifyAuth();
              window.open(authUrl, '_blank', 'width=500,height=600');
              throw new Error('Spotify authentication required');
            }
            console.error(`Error searching for track: ${searchQuery}`, error);
            return null;
          }
        })
      );

      const validTrackIds = spotifyTrackIds.filter(id => id);

      if (validTrackIds.length === 0) {
        throw new Error('No valid tracks found on Spotify');
      }

      const playlistName = `${tracklist.name} - idbro`;
      const createdPlaylist = await createSpotifyPlaylist(playlistName, validTrackIds);
      console.log('Playlist created:', createdPlaylist);
      
      // Show success message
      setSnackbarMessage(`Playlist "${playlistName}" created successfully!`);
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error creating Spotify playlist:', error);
      
      // Show error message
      setSnackbarMessage(`Error creating playlist: ${error.message}`);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <Button onClick={handleCreatePlaylist} variant="contained" sx={{ backgroundColor: '#1DB954', '&:hover': { backgroundColor: '#1ed760' } }}>
        Create Spotify Playlist
      </Button>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CreateSpotifyPlaylistButton;