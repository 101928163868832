import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  Person as PersonIcon,
  Security as SecurityIcon,
  Payment as PaymentIcon,
  PrivacyTip as PrivacyTipIcon,
  Notifications as NotificationsIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import ProfileSection from '../components/AccountSettings/ProfileSection';
import SecuritySection from '../components/AccountSettings/SecuritySection';
import BillingSection from '../components/AccountSettings/BillingSection';
import PrivacySection from '../components/AccountSettings/PrivacySection';
import NotificationsSection from '../components/AccountSettings/NotificationsSection';
import AccountSection from '../components/AccountSettings/AccountSection';
import { useAuth } from '../contexts/AuthContext';
import { getUserProfile } from '../services/api';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  color: theme.palette.common.white,
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const sections = [
  { label: 'Profile', icon: <PersonIcon />, component: ProfileSection },
  { label: 'Security', icon: <SecurityIcon />, component: SecuritySection },
  { label: 'Billing & Subscriptions', icon: <PaymentIcon />, component: BillingSection },
  { label: 'Privacy', icon: <PrivacyTipIcon />, component: PrivacySection },
  { label: 'Notifications', icon: <NotificationsIcon />, component: NotificationsSection },
  { label: 'Account', icon: <DeleteIcon />, component: AccountSection },
];

function AccountSettings() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getUserProfile(user.id);
        console.log('Fetched user profile:', profile); // Add this line for debugging
        setUserProfile(profile);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [user.id]);

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const SelectedComponent = sections[selectedIndex].component;

  return (
    <Box sx={{ position: 'relative', minHeight: '100vh', overflow: 'hidden' }}>
      <Box sx={{ p: { xs: 2, md: 4 }, position: 'relative', zIndex: 1 }}>
        <Typography variant="h4" gutterBottom sx={{ color: 'common.white' }}>
          Account Settings
        </Typography>
        <StyledPaper elevation={0} sx={{ p: { xs: 0, md: 2 } }}>
          <Grid container spacing={4}>
            {!isMobile && (
              <Grid item xs={12} md={3}>
                <StyledPaper elevation={1}>
                  <List component="nav" aria-label="settings sections">
                    {sections.map((section, index) => (
                      <div key={section.label}>
                        <StyledListItemButton
                          selected={selectedIndex === index}
                          onClick={() => handleListItemClick(index)}
                        >
                          <ListItemIcon sx={{ color: 'common.white' }}>{section.icon}</ListItemIcon>
                          <ListItemText primary={section.label} />
                        </StyledListItemButton>
                        {index < sections.length - 1 && <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />}
                      </div>
                    ))}
                  </List>
                </StyledPaper>
              </Grid>
            )}
            <Grid item xs={12} md={9}>
              <StyledPaper elevation={1} sx={{ p: { xs: 2, md: 4 } }}>
                <SelectedComponent userProfile={userProfile} setUserProfile={setUserProfile} />
              </StyledPaper>
            </Grid>
          </Grid>
        </StyledPaper>
      </Box>
    </Box>
  );
}

export default AccountSettings;